import React from "react";
import './ImageChartModule.css'
import { baseApiUrl } from "../../actions";

class ImageChartModule extends React.Component {
  render() {
    return (
      <div className={`chartModule imageChartModule roundedShadowBox ${this.props.className || ''}`}>
        {this.props.data.charts.map(chart =>
          <img key={chart.name}
               alt={chart.name}
               width={chart.width / 2}
               src={`${baseApiUrl}/chart_images/${chart.id}`}/>
        )}
      </div>
    )
  }
}

export default ImageChartModule;
