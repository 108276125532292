import React from 'react'
import './NavHeader.css'

class NavHeader extends React.Component {
  render() {
    return (
      <div className='navHeader'>
        <div className='navLogo' onClick={() => this.props.onClick(this.props.logoAction)}>ecoCity Footprint Tool</div>
				<div className='navItems'>
					{this.props.items.map((item, index) =>
						<span
							key={index}
							className={this.props.activePage === item.type ? 'selected' : ''}
							onClick={() => this.props.onClick(item.type)}
						>
							{item.title}
						</span>
					)}
					<div className='navRightButton' onClick={() => this.props.onClick(this.props.rightButtonAction)} >
            {this.props.rightButtonTitle}
					</div>
				</div>
      </div>
    )
  }
}

export default NavHeader;
