import { combineReducers } from 'redux'
import {
  Pages,
  NAVIGATE,
  LOGOUT_REQUEST,
  SET_ACTIVE_DATASET,
  SET_MODAL,
  DISMISS_MODAL,
  RESTRICT_SIGNUP,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST_FAILED,
  FORGOT_PASSWORD_REQUEST_COMPLETE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_REQUEST_FAILED,
  RESET_PASSWORD_REQUEST_COMPLETE,
  SET_UNSAVED_DATA,
  CLEAR_UNSAVED_DATA,
} from '../actions'
import datasetSpecs from './datasetSpecs'
import {datasetList, userDatasetLists} from './datasetList'
import datasets from './datasets'
import datasetOutputs from './datasetOutputs'
import { loggedIn, loggingIn, loginFailed } from './login'
import user from './user'
import users from './adminUsers'
import whitelistCodes from './whitelistCodes';

function disableSignup(state = RESTRICT_SIGNUP, action) {
  return state;
}

function nav(state = { page: Pages.HOME, params: {} }, action) {
  if (action.type === NAVIGATE) {
    return {
      page: action.page,
      params: action.params || {},
    };
  } else {
    return state;
  }
}

function activeDatasetId(state = null, action) {
  switch (action.type) {
    case SET_ACTIVE_DATASET:
      return action.id;
    case LOGOUT_REQUEST:
      return null;
    default:
      return state;
  }
}

export const APIState = {
  NOT_STARTED: 'NOT_STARTED',
  LOADING: 'LOADING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
};

function forgotPassword(state = APIState.NOT_STARTED, action) {
  return {
    [FORGOT_PASSWORD_REQUEST]: APIState.LOADING,
    [FORGOT_PASSWORD_REQUEST_COMPLETE]: APIState.COMPLETE,
    [FORGOT_PASSWORD_REQUEST_FAILED]: APIState.FAILED
  }[action.type] || state;
}

function resetPassword(state = APIState.NOT_STARTED, action) {
  return {
    [RESET_PASSWORD_REQUEST]: APIState.LOADING,
    [RESET_PASSWORD_REQUEST_COMPLETE]: APIState.COMPLETE,
    [RESET_PASSWORD_REQUEST_FAILED]: APIState.FAILED
  }[action.type] || state;
}

function modal(state = null, action) {
  switch (action.type) {
    case SET_MODAL:
      return {
        type: action.modalType,
        data: action.data
      };
    case DISMISS_MODAL:
      return null;

    default:
      return state;
  }
}

function unsavedData(state = false, action) {
  switch (action.type) {
    case SET_UNSAVED_DATA:
      return true;
    case NAVIGATE:
    case CLEAR_UNSAVED_DATA:
      return false;

    default:
      return state;
  }
}
 
const entities = combineReducers({
  datasetSpecs,
  datasetList,
  datasets,
  datasetOutputs
});

const admin = combineReducers({
  whitelistCodes,
  users,
  userDatasetLists,
});

export default combineReducers({
  activeDatasetId,
  admin,
  disableSignup,
  entities,
  forgotPassword,
  loggingIn,
  loggedIn,
  loginFailed,
  modal,
  nav,
  resetPassword,
  unsavedData,
  user,
});

