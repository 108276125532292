import React from "react";
import './DeleteDatasetConfirm.css'
import {deleteDataset, dismissModal} from "../../actions";
import {connect} from "react-redux";

class DeleteDatasetConfirm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      failed: false,
      loading: false
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  render() {
    return (
      <form className='deleteDatasetConfirm' onSubmit={(event) => event.preventDefault()}>
        <h3>Delete dataset</h3>
        <p className='error'>{this.state.failed ? 'Failed to delete dataset' : ''}</p>
        <p>Are you sure you want to delete this dataset? This cannot be undone.</p>

        <div className='buttonContainer'>
          <input type='submit'
                 className='cancelButton'
                 value='Cancel'
                 disabled={this.state.loading}
                 onClick={this.props.dismissModal} />
          <input type='submit'
                 className='deleteButton'
                 value='Delete'
                 disabled={this.state.loading}
                 onClick={this.handleDelete}/>
        </div>
      </form>
    )
  }

  handleDelete(event) {
    this.setState({
      loading: true,
      failed: false
    });

    this.props.deleteDataset(this.props.datasetId)
      .then(() => {
        this.setState({
          loading: false,
          failed: false
        });
        this.props.handleSuccess();
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          failed: true
        });
      });
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteDataset: (id) => dispatch(deleteDataset(id)),
  dismissModal: () => dispatch(dismissModal())
});

export default connect(
  null,
  mapDispatchToProps
)(DeleteDatasetConfirm);
