import React from 'react'
import './Home.css'
import SignupForm from './SignupForm'
import LoginForm from './LoginForm'
import ForgotPasswordForm from './ForgotPasswordForm'
import {FORGOT_PASSWORD_FORM, LOGIN_FORM, SIGNUP_FORM, RESET_PASSWORD_FORM} from "../../actions";
import ResetPasswordForm from "./ResetPasswordForm";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.signupButtonClicked = this.signupButtonClicked.bind(this);
  }

  signupButtonClicked() {
    this.props.setAuthForm(SIGNUP_FORM);
    window.scrollTo(0, 0);
  }

  render () {
    if (this.props.fetchingUser) {
      return (
        <div className='home'>
          <p>Loading...</p>
        </div>
      )
    } else {
      const authFormTitles = {
        [LOGIN_FORM]: 'Login',
        [SIGNUP_FORM]: 'Create an Account',
        [FORGOT_PASSWORD_FORM]: 'Forgot Password',
        [RESET_PASSWORD_FORM]: 'Reset Password',
      };
      return (
        <div className='home'>
          <div className='signupSectionContainer'>
            <div className='mainSummary'>
              <h1>Enabling cities to understand and lighten their ecological and carbon footprints.</h1>
            </div>
            <div className='signupContainer'>
              <p className='formHeader'>{authFormTitles[this.props.authForm]}
                {(!this.props.disableSignup || this.props.authForm !== LOGIN_FORM) &&
                  <span className='formHeaderSubtext'>{' or, '}
                    <span className='switchFormText' onClick={ () => this.props.toggleAuthForm() }>
                      {this.props.authForm === LOGIN_FORM ? 'Create an Account' : 'Login'}
                    </span>
                  </span>
                }
              </p>
              <div style={ {height: 0, visibility: this.props.authForm === LOGIN_FORM ? 'visible' : 'hidden'} }>
                <LoginForm
                  onSubmit={this.props.doLogin}
                  loading={this.props.loggingIn}
                  failed={this.props.loginFailed}
                  disableSignup={this.props.disableSignup}
                  gotoSignup={() => this.props.setAuthForm(SIGNUP_FORM)}
                  gotoForgotPassword={() => this.props.setAuthForm(FORGOT_PASSWORD_FORM)}
                />
              </div>
              <div style={ {height: 0, visibility: this.props.authForm === FORGOT_PASSWORD_FORM ? 'visible' : 'hidden'} }>
                <ForgotPasswordForm
                  onSubmit={this.props.doForgotPassword}
                  apiState={this.props.forgotPassword}
                />
              </div>
              <div style={ {height: 0, visibility: this.props.authForm === RESET_PASSWORD_FORM ? 'visible' : 'hidden'} }>
                <ResetPasswordForm
                  onSubmit={this.props.doResetPassword}
                  token={this.props.passwordResetToken}
                  email={this.props.email}
                  gotoLogin={() => this.props.setAuthForm(LOGIN_FORM)}
                  apiState={this.props.resetPassword}
                />
              </div>
              <div style={ {visibility: this.props.authForm === SIGNUP_FORM ? 'visible' : 'hidden'} }>
                <SignupForm
                  onSubmit={this.props.doSignup}
                  loading={this.props.signingUp}
                  showTerms={this.props.showTerms}
                  restrictSignup={this.props.disableSignup}
                />
              </div>
            </div>
          </div>
          <div className='aboutSectionContainer'>
            <div className='mainTextContainer'>
              <h2>About the Tool</h2>
              <p>The ecoCity Footprint Tool helps communities evaluate the extent to which local consumption habits are drawing down our planet’s resources and contributing to climate change. It does this by illuminating how much resources (energy and materials), and how much land and sea area are required to produce all the goods and services consumed within the community on a given year.</p>
              <p>Many of these resources are extracted outside of the community’s borders, but using the Tool the community gains the means to understand the global impact of local consumption habits.</p>
            </div>
            <div className='rightContainer'>
              <div className='item one'>
                <div className='icon'/>
                <div className='number'>1</div>
                <p>Municipality enters data to produce the carbon-emission inventories and ecological footprint.</p>
              </div>
              <div className='item two'>
                <div className='icon'/>
                <div className='number'>2</div>
                <p>Provides data so that cities can identify priorities for acting on climate change and unsustainable resource consumption.</p>
              </div>
              <div className='item three'>
                <div className='icon'/>
                <div className='number'>3</div>
                <p>Users can compare different scenarios to better understand discrepancies between current footprint and ‘one-earth’ levels.</p>
              </div>
              <div className='item four'>
                <div className='icon'/>
                <div className='number'>4</div>
                <p>Supports effective communication with stakeholders about the rationale for, and benefits of sustainable consumption and climate action.</p>
              </div>
              {!this.props.disableSignup &&
                <div className='createAccountButton' onClick={this.signupButtonClicked}>Create an Account</div>
              }
            </div>
          </div>
          <div className='backgroundSectionContainer'>
            <div className='backgroundImage' />
            <div className='contentContainer'>
              <h2>Tool Background</h2>
              <p>The tool uses standardized measures — the ecological footprint and greenhouse gas emissions. The Tool was initially created by Dr. Jennie Moore, Director, Institute Sustainability, BCIT, as part of her PhD, under the supervision of Dr. William Rees, founder of the ecological footprint concept.</p>
              <p>Prototyped in 2006 and used by the City of Vancouver to inform two iterations of its ground-breaking “Greenest City Action Plan” (2011 and 2020), the tool has since been tested in municipalities in Canada, the United States, and other countries.</p>
            </div>
          </div>
      	</div>
      )
    }
	}
}

export default Home
