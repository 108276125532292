import React from "react";
import './ChartModule.css'
import '../../../node_modules/react-vis/dist/style.css';
import RadialChart from "react-vis/es/radial-chart";
import { Hint } from "react-vis/es";
import ChartLegend from "./ChartLegend";

class ChartModule extends React.Component {
  constructor(props) {
    super(props);

    this.colors = [
      '#DEE880',
      '#FCDD7F',
      '#F5BCAB',
      '#BC98D0',
      '#6C5C90',
      '#50ACC5',
      '#E88080',
      '#66BC66'
    ];

    this.state = {
      hoveredItem: false
    };
  }

  render() {
    const rows = this.props.data.rows.filter(row => row.unit && !isNaN(Number(row.value)));
    let colors = rows.map(row => row.color).filter(c => !!c);
    colors = colors.map(c => '#' + c);
    if (colors.length === 0) {
      colors = this.colors;
    }

    let total = 0;
    let totalUnit = null;
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      total += Number(row.value);
      if (row.unit && totalUnit === null) {
        totalUnit = row.unit;
      } else if (row.unit && row.unit !== totalUnit) {
        totalUnit = null;
        total = null;
        break;
      }
    }

    const getPercent = (value) => {
      const percent = Math.round( value / total * 100);
      return percent > 5 ? `${percent}%` : '';
    };

    return (
      <div className={`chartModule roundedShadowBox ${this.props.className || ''}`}>
        <h3>{this.props.data.title}</h3>
        <div className='chartContainer'>
          <RadialChart width={200}
                       height={200}
                       innerRadius={32}
                       radius={95}
                       getAngle={d => d.theta}
                       getLabel={d => getPercent(d.theta)}
                       labelsRadiusMultiplier={0.8}
                       showLabels
                       colorType='literal'
                       onValueMouseOver={v => this.setState({hoveredItem: v})}
                       onSeriesMouseOut={v => this.setState({hoveredItem: false})}
                       data={
                         rows.map( (row, index) => ({
                           theta: row.value,
                           label: row.label,
                           color: colors[index % colors.length]
                         }))
                       }
          >
            {
              this.state.hoveredItem !== false &&
              <Hint value={ {Label: this.state.hoveredItem.label} } />
            }
          </RadialChart>

          <ChartLegend rows={rows} colors={colors} total={total} totalUnit={totalUnit} />
        </div>
      </div>
    )
  }
}

export default ChartModule;
