import React from "react";
import GridLines from './GridLines'
import './SustainGapModule.css'
import ChartLegend from "./ChartLegend";

class SustainGapModule extends React.Component {
  render() {
    const onePlanet = 1.5; // TODO: Get from Excel somehow
    const rows = this.props.data.rows.filter(row => row.unit && !isNaN(Number(row.value)));
    const total = rows.reduce( (a, b) => a + Number(b.value), 0);
    const range = Math.max(total, onePlanet * 1.1);
    let colors = this.props.data.rows.map(row => row.color).filter(c => !!c);
    colors = colors.map(c => '#' + c);
    if (colors.length === 0) {
      colors = [
        '#9BBB59',
        '#FFC001',
        '#4BACC5',
        '#77933D',
        '#F79646',
        '#E46C0B'
        ]
    }
    const labelWidth = 130;
    const barWidth = 130;
    const padding = 10;
    const gapValue = total - onePlanet;
    const gapPercent = Math.round(gapValue / total * 100);

    return (
      <div className={`chartModule sustainGapModule roundedShadowBox ${this.props.className || ''}`}>
        <h3>{this.props.data.title}</h3>
        <div className='chartContainer'>
          <div className='chartArea'>
            <GridLines totalValue={range} unit={rows[0].unit} lineCount={3} labelWidth={labelWidth} />
            <div className='barContainer' style={ {
              height: Math.round(total / range * 100) + '%',
              width: barWidth + 'px',
              left: (labelWidth + padding) + 'px'
            } }>
              {rows.map( (row, index) =>
                <div className='barItem'
                     key={index}
                     style={ {
                       backgroundColor: colors[index % colors.length],
                       height: Math.round(row.value / total * 100) + '%'
                     } }
                />
              )}
            </div>
            <div className='onePlanetLine' style={ {
              bottom: Math.round(onePlanet / range * 100) + '%',
              left: labelWidth + 'px'
            } } />
            <div className='onePlanetText' style={ {top: Math.round(100 - (onePlanet / range * 100)) + '%'} }>
              One Planet<br />{onePlanet} gha/ca
            </div>
            <div className='gapContainer' style={ {
              height: Math.round(Math.abs(gapValue) / range * 100) + '%',
              left: (labelWidth + barWidth + padding + 5) + 'px',
              bottom: Math.round((onePlanet + Math.min(gapValue, 0)) / range * 100) + '%'
            } }>
              <div className='bracketLeft' />
              <div className='bracketRightContainer'>
                <div className='bracketTop' />
                <div className='bracketBottom' />
              </div>
              <div className='text'>{gapPercent}%</div>
            </div>
          </div>
          <ChartLegend rows={rows} colors={colors} total={total} totalUnit={rows[0].unit} />
        </div>
      </div>
    )
  }


}

export default SustainGapModule;
