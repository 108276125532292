import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_COMPLETE,
  LOGIN_REQUEST_FAILED,
  USER_REQUEST_COMPLETE,
  USER_REQUEST_FAILED,
  LOGOUT_REQUEST
} from '../actions'

export function loggingIn(state = false, action) {
  switch (action.type) {
    case LOGIN_REQUEST: 
      return true;
    case LOGIN_REQUEST_COMPLETE:
    case LOGIN_REQUEST_FAILED:
      return false;
    default:
      return state
  }
}

export function loggedIn(state = false, action) {
  switch (action.type) {
    case USER_REQUEST_COMPLETE:
    case LOGIN_REQUEST_COMPLETE: 
      return true;
    case USER_REQUEST_FAILED:
    case LOGIN_REQUEST_FAILED:
    case LOGOUT_REQUEST:
      return false;
    default:
      return state
  }
}

export function loginFailed(state = false, action) {
  switch (action.type) {
    case LOGIN_REQUEST_COMPLETE:
      return false;
    case LOGIN_REQUEST_FAILED:
      return true;
    default:
      return state
  }
}
