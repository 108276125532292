import {
  ADD_WHITELIST_CODES_REQUEST_COMPLETE,
  WHITELIST_CODES_REQUEST,
  WHITELIST_CODES_REQUEST_COMPLETE,
  WHITELIST_CODES_REQUEST_FAILED, DELETE_DATASET_REQUEST_COMPLETE
} from '../actions'

const whitelistCode = (code) => ({
  id: code.id,
  code: code.code,
  createdBy: code.created_by,
  redeemedBy: code.redeemed_by,
});

function whitelistCodes(state = {
  fetching: false,
  items: null
}, action) {
  switch (action.type) {
    case WHITELIST_CODES_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
    case WHITELIST_CODES_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
    case WHITELIST_CODES_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        fetching: false,
        items: action.json.codes.map(whitelistCode)
      });
    case ADD_WHITELIST_CODES_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        items: [...state.items, ...action.json.codes.map(whitelistCode)]
      });
    case DELETE_DATASET_REQUEST_COMPLETE:
      if (!state.items) {
        return state;
      }
      return Object.assign({}, state, {
        items: state.items.filter(d => d.id !== action.id)
      });
    default:
      return state
  }
}

export default whitelistCodes;
