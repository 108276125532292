import React from 'react'
import './LoginForm.css'
import './AuthForm.css'

class LoginForm extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: props.email || '',
			password: props.password || '',
		};
		
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	render() {
		return (
			<form className='loginForm authForm' onSubmit={this.handleSubmit}>
				{this.props.disableSignup &&
					<div className='extraText'>
						<p>An online version of the Tool is being tested now by BC communities. If you are interested in using the Tool when broadly available, please send us an email: <a href='mailto:ecocityfootprint@bcit.ca'>ecocityfootprint@bcit.ca</a>.</p>
						<p>If you have a signup code, <span className='textLink' onClick={() => this.props.gotoSignup()}>click here</span>.</p>
					</div>
				}
				<p className='error'>{this.props.failed ? 'Login failed' : ''}</p>
				<input type='text'
							 placeholder='Email'
							 name='email'
							 id='loginEmail'
							 autoComplete='username'
							 onChange={this.handleChange}
							 value={this.state.email}
							 disabled={this.props.loading} />

				<input type='password'
							 placeholder='Password'
							 name='password'
							 id='loginPassword'
							 autoComplete='current-password'
							 onChange={this.handleChange}
							 value={this.state.password}
							 disabled={this.props.loading} /><br />
				
				<input type='submit' value='Login' disabled={this.props.loading} />

				<p><span className='textLink' onClick={() => this.props.gotoForgotPassword()}>Forgot password?</span></p>
			</form>
		)
	}
	
	handleChange(event) {
		let changes = {};
		changes[event.target.name] = event.target.value;
		this.setState(changes);
	}
	
	handleSubmit(event) {
    event.preventDefault();
    let data = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.onSubmit(data);
	}
}

export default LoginForm;
