import React from 'react'
import './AuthForm.css'
import { APIState } from '../../reducers'

class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.email || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <form className='forgotPasswordForm authForm' onSubmit={this.handleSubmit}>
        <p>Enter your email to receive a link to reset your password.</p>
        {this.props.apiState === APIState.COMPLETE &&
          <p className='success'>A link to reset your password was sent, if that account exists.</p>
        }
        {this.props.apiState === APIState.FAILED &&
          <p className='error'>Failed to send password reset code.</p>
        }
        <input type='text'
               placeholder='Email'
               name='email'
               autoComplete='username'
               onChange={this.handleChange}
               value={this.state.email}
               disabled={this.props.apiState === APIState.LOADING} /><br />

        <input type='submit' value='Forgot Password' disabled={this.props.apiState === APIState.LOADING} />
      </form>
    )
  }

  handleChange(event) {
    let changes = {};
    changes[event.target.name] = event.target.value;
    this.setState(changes);
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = {
      email: this.state.email,
    };
    this.props.onSubmit(data);
  }
}

export default ForgotPasswordForm;
