import React from 'react'
import { connect } from 'react-redux'
import './App.css'
import HomeContainer from '../containers/HomeContainer'
import DashboardContainer from '../containers/DashboardContainer'
import QuestionnaireContainer from '../containers/QuestionnaireContainer'
import NavHeaderContainer from '../containers/NavHeaderContainer'
import {dismissModal, navigate, Pages} from '../actions'
import Footer from './Footer';
import TermsPage from './TermsPage';
import Modal from "./modal/Modal";
import AdminContainer from '../containers/AdminContainer';

const App = ({ page, showTerms, modal, dismissModal }) =>	(
  <div className='App'>
    <NavHeaderContainer />
    {(function() {
      switch(page) {
        case Pages.HOME:
          return <HomeContainer />;
        case Pages.QUESTIONNAIRE:
          return <QuestionnaireContainer />;
        case Pages.DASHBOARD:
          return <DashboardContainer />;
        case Pages.TERMS:
          return <TermsPage />;
        case Pages.ADMIN:
          return <AdminContainer />;
        default:
          return null
      }
    })()}
    <Footer showTerms={showTerms} />
    {modal &&
      <Modal type={modal.type} data={modal.data} handleDismiss={dismissModal} />
    }
	</div>
);

const mapStateToProps = (state, ownProps) => ({
  page: state.nav.page,
  modal: state.modal
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showTerms: () => dispatch(navigate(Pages.TERMS)),
  dismissModal: () => dispatch(dismissModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App)
