import { DATASET_SPECS_REQUEST, DATASET_SPECS_REQUEST_FAILED, DATASET_SPECS_REQUEST_COMPLETE } from '../actions'

function datasetSpecs(state = {
  fetching: false,
  items: null
}, action) {
  switch (action.type) {
    case DATASET_SPECS_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
    case DATASET_SPECS_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
    case DATASET_SPECS_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        fetching: false,
        items: action.json.dataset_specs.map(spec => ({
          id: spec.id,
          pages: spec.pages.map(page => ({
            name: page.name,
            title: page.title,
            introText: page.intro_text,
            sectionGroups: page.section_groups.map(group => ({
              id: group.id,
              name: group.name,
              title: group.title,
              subtitle: group.subtitle,
              description: group.description,
              type: group.type,
              sections: group.sections.map(section => ({
                id: section.id,
                title: section.title,
                tooltip: section.tooltip,
                rows: section.rows.map((row, index) => ({
                  label: row.label,
                  defaultValue: row.default_value,
                  unit: row.unit,
                  index
                }))
              }))
            }))
          }))
        }))
      });
    default:
      return state
  }
}

export default datasetSpecs;
