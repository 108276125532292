import { 
  DATASET_OUTPUT_REQUEST,
  DATASET_OUTPUT_REQUEST_COMPLETE,
  DATASET_OUTPUT_REQUEST_FAILED
} from '../actions'

function datasetOutput(state = {
  fetching: false
}, action) {
    switch (action.type) {
    case DATASET_OUTPUT_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
    case DATASET_OUTPUT_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
    case DATASET_OUTPUT_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        fetching: false,
        sectionGroups: action.json.section_groups.map(group => ({
          name: group.name,
          isComplete: group.is_complete,
          title: group.title,
          subtitle: group.subtitle,
          icon: group.icon,
          navGroup: group.nav_group,
          sections: group.sections.map(section => ({
            id: section.id,
            title: section.title,
            chartType: section.chart_type,
            rows: section.rows.map((row) => ({
              label: row.label,
              value: row.value,
              unit: row.unit,
              color: row.color,
            })),
            charts: section.charts.map((chart) => ({
              id: chart.id,
              width: chart.width,
              height: chart.height,
              name: chart.name,
            }))
          }))
        }))
      });
    default:
      return state
  }
}

function datasetOutputs(state = {}, action) {
  switch (action.type) {
    case DATASET_OUTPUT_REQUEST:
    case DATASET_OUTPUT_REQUEST_COMPLETE:
    case DATASET_OUTPUT_REQUEST_FAILED:
      return Object.assign({}, state, {
        [action.id]: datasetOutput(state[action.id], action)
      });
    default:
      return state
  }
}

export default datasetOutputs;
