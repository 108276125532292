import React from "react";
import './GridLines.css'
import NumberFormat from "react-number-format";

const GridLines = ( {totalValue, lineCount, unit, labelWidth} ) => {
  const precision = 2;
  const unroundedIncrement = totalValue / lineCount;
  const magnitude = Math.floor(Math.log10(unroundedIncrement));
  const divisor = Math.pow(10, magnitude);
  const increment = Math.floor((unroundedIncrement / divisor) * precision) / precision * divisor;

  return (
    <div className='gridLines'>
      {Array(lineCount + 1).fill(1).map( (_, index) =>
        <div className='gridLine'
             key={index}
             style={ {bottom: Math.round((index * increment) / totalValue * 100) + '%'} }
        >
          <div className='value' style={ {width: labelWidth + 'px'} }>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={index * increment}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          suffix={` ${unit}`}
            />
          </div>
          <div className='line' />
        </div>
      )}
    </div>
  );
};

export default GridLines;