import React from 'react'
import './AtAGlanceRow.css'
import NumberFormat from "react-number-format";

class AtAGlanceRow extends React.Component {
  render() {
    return (
      <div className={`atAGlanceRow ${this.props.className || ''}`}>
        <h4 className='title'>{this.props.title}</h4>
        <div className='totalContainer numberContainer'>
          <div className='value'>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={this.props.totalValue}
                          decimalScale={0}
                          thousandSeparator={true} /> gha
          </div>
          <div className='caption'>{this.props.totalCaption}</div>
          <div className='summary'>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={this.props.totalMultiplier}
                          decimalScale={0}
                          thousandSeparator={true} />x larger than the city area
          </div>
        </div>
        <div className='perPersonContainer numberContainer'>
          <div className='value'>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={this.props.perPersonValue}
                          decimalScale={2}
                          thousandSeparator={true} /> gha
            </div>
          <div className='caption'>{this.props.perPersonCaption}</div>
          <div className='summary'>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={this.props.perPersonAvailable}
                          decimalScale={2}
                          thousandSeparator={true} /> gha per person available
          </div>
        </div>
        <div className='earthsContainer'>
          <div className='icons'>
            {Array(Math.ceil(this.props.earthCount)).fill(1).map( (_, i) =>
              <div key={i}>
                <div style={{width: i + 1 > this.props.earthCount ? `${(this.props.earthCount % 1) * 100}%` : ''}} />
              </div>
            )}
          </div>
          <div className='summary'>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={this.props.earthCount}
                          decimalScale={1}
                          thousandSeparator={true} />
            {' Earths needed'}
          </div>
        </div>
      </div>
    )
  }
}

export default  AtAGlanceRow;