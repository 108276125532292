import React from 'react'
import './Footer.css'

class Footer extends React.Component {
  render() {
    return (
      <div className='footer'>
        <div className='leftItems'>
          <div>&copy; ecoCity Footprint Tool 2019</div>
          <div className='hidden'>About</div>
          <div className='hidden'>Resources</div>
          <div className='hidden'>Contact</div>
        </div>
        <div className='rightItems'>
          <div onClick={this.props.showTerms}>Terms &amp; Privacy Policy</div>
        </div>
      </div>
    )
  }
}

export default Footer;
