import React from 'react'
import NumberFormat from "react-number-format";
import './ChartLegend.css'

const ChartLegend = ( {rows, colors, total, totalUnit} ) => (
  <div className='legendScrollContainer'>
    <div className='legend'>
      {rows.map( (row, index) => (
        <div key={index} className='valueContainer'>
          <div className='icon' style={ {backgroundColor: colors[index % colors.length]} } />
          <div className='label'>{row.label}</div>
          <div className='value'>
            <NumberFormat isNumericString={true}
                          displayType='text'
                          value={Number(row.value)}
                          decimalScale={row.value < 0.1 ? 3 : 2}
                          thousandSeparator={true} />
            <span> {row.unit}</span>
          </div>
        </div>
      ))}
      {totalUnit &&
      <div className='valueContainer total'>
        <div className='label'>Total</div>
        <div className='value'>
          <NumberFormat isNumericString={true}
                        displayType='text'
                        value={Number(total)}
                        decimalScale={total < 0.1 ? 3 : 2}
                        thousandSeparator={true} />
          <span> {totalUnit}</span>
        </div>
      </div>
      }
    </div>
    <div className='bottomFade'/>
  </div>
);

export default ChartLegend;