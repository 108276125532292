import React from 'react';
import './PageSelector.css';

const PageSelector = ( { pages, selectedPage, handleClick } ) => (
  <div className={'pageSelector'}>
    {pages.map( (page, index) =>
      <div key={page.name}
           className={`pageSelectorItem ${page.name} ${page.name === selectedPage ? 'selected' : ''}`}
           onClick={ () => handleClick(page) }>
        <span>{page.title}</span>
      </div>
    )}
  </div>
);

export default PageSelector;