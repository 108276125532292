import {
} from '../actions'
import {
  ADMIN_USERS_REQUEST,
  ADMIN_USERS_REQUEST_FAILED,
  ADMIN_USERS_REQUEST_COMPLETE,
  ADMIN_DELETE_USER_REQUEST_COMPLETE,
} from "../actions";

const user = (code) => ({
  id: code.id,
  email: code.email,
  firstName: code.first_name,
  lastName: code.last_name,
  admin: code.admin,
});

function users(state = {
  fetching: false,
  items: null
}, action) {
  switch (action.type) {
    case ADMIN_USERS_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
    case ADMIN_USERS_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
    case ADMIN_USERS_REQUEST_COMPLETE:
    case ADMIN_DELETE_USER_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        fetching: false,
        items: action.json.users.map(user)
      });
    default:
      return state
  }
}

export default users;
