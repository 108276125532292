import {
  CREATE_DATASET_REQUEST_COMPLETE,
  DATASET_REQUEST,
  DATASET_REQUEST_COMPLETE,
  DATASET_REQUEST_FAILED, DELETE_DATASET_REQUEST_COMPLETE,
  SAVE_DATASET_REQUEST,
  SAVE_DATASET_REQUEST_COMPLETE,
  SAVE_DATASET_REQUEST_FAILED,
} from '../actions'

function mapValues(object, mapFunction) {
  return Object.keys(object).reduce(function(result, key) {
    result[key] = mapFunction(object[key]);
    return result;
  }, {});
}

const mapDataset = (json) => ({
  specId: json.spec_id,
  id: json.id,
  version: json.version,
  name: json.name,
  city: json.city,
  year: json.year,
  sections: mapValues(json.sections, (section) => ({
    id: section.id,
    specSectionId: section.spec_section_id,
    rows: mapValues(section.rows, (row) => ({
      value: row.value,
      source: row.source,
      notationKey: row.notation_key,
      quality: row.quality
    }))
  }))
});

function dataset(state = {
  fetching: false,
  saving: false
}, action) {
  switch (action.type) {
    case DATASET_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
    case DATASET_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
    case CREATE_DATASET_REQUEST_COMPLETE:
      return Object.assign({}, state, mapDataset(action.json.dataset));
    case DATASET_REQUEST_COMPLETE:
      return Object.assign({}, state, mapDataset(action.json),{fetching: false});
    case SAVE_DATASET_REQUEST:
      return Object.assign({}, state, {
        saving: true
      });
    case SAVE_DATASET_REQUEST_FAILED:
    case SAVE_DATASET_REQUEST_COMPLETE:
      return Object.assign({}, state, mapDataset(action.json.dataset), {
        saving: false
      });
    default:
      return state
  }
}

function datasets(state = {}, action) {
  switch (action.type) {
    case DATASET_REQUEST:
    case DATASET_REQUEST_FAILED:
    case DATASET_REQUEST_COMPLETE:
    case SAVE_DATASET_REQUEST:
    case SAVE_DATASET_REQUEST_FAILED:
    case SAVE_DATASET_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        [action.id]: dataset(state[action.id], action)
      });
    case CREATE_DATASET_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        [action.json.id]: dataset(state[action.json.id], action)
      });
    case DELETE_DATASET_REQUEST_COMPLETE:
      if (!state[action.id]) {
        return state;
      }
      const { [action.id]: _,  ...withoutDataset} = state;
      return withoutDataset;
    default:
      return state
  }
}

export default datasets;