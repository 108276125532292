import React from "react";
import NumberFormat from "react-number-format";
import './InputItem.css';
import Tooltip from "./Tooltip";

const inputElement = ( { name, placeholder, value, allowText, disableThousandCammas, handleChange, handleKeyDown} ) => {
  if ((placeholder || '').match(/\[.+]/)) {
    return (<select
      name={name}
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    >
      {placeholder.match(/\[(.+)]/)[1].split('/').map(option =>
        <option key={option} value={option}>{option}</option>
      )}
    </select>);
  } else if (allowText) {
    return (<input
      name={name}
      type='text'
      placeholder={placeholder || ''}
      value={value || ''}
      onChange={handleChange}
      onKeyDown={handleKeyDown}/>);
  } else {
    return (<NumberFormat
      displayType={'input'}
      name={name}
      thousandSeparator={!disableThousandCammas}
      placeholder={placeholder || ''}
      value={value || ''}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
    />);
  }
};

const InputItem = ( { name, label, placeholder, value, allowText, disableThousandCammas, unit, hasSources, handleChange, handleKeyDown, handleSources, tooltip, className } ) => (
  <div className={`inputItem ${className || ''}`}>
    <div className='labelLine'>
      <label htmlFor={name}>{label} {tooltip && <Tooltip text={tooltip} />}</label>
      {handleSources &&
      <span className={`sourcesContainer ${hasSources ? 'withSources' : ''}`} onClick={() => handleSources(name)}>
          <span className='text'>Sources</span> <span className='tagIcon'/>
        </span>
      }
    </div>
    <div className={`inputFieldContainer ${value !== '' ? 'withData' : ''}`}>
      {inputElement({ name, placeholder, value, allowText, disableThousandCammas, handleChange, handleKeyDown})}
      <div className='inputUnitLabel'>{unit || ''}</div>
    </div>
  </div>
);

export default InputItem;
