import React from 'react'
import './TermsPage.css'

const TermsPage = () => (
  <div className='termsPage'>
    <h2>Site Terms &amp; Privacy Policy</h2>
    <h3>Site Terms</h3>

    <h4>User license agreement</h4>
    <p>JM Sustainability Managers Inc ("we," "us," or "our") thanks you for choosing to access and use EcoCity Footprint Tool(“EFT,”) our service (the "Service") made available to you through our website at <a href="https://www.ecocityfootprint.org/">https://www.ecocityfootprint.org/</a> (the "Website"). We provide access to and use of our Service subject to the following terms of service (the "Terms of Service"). By using our Service, you acknowledge that you have read, understood, and agree to be legally bound by the terms and conditions of these Terms of Service and the terms and conditions of our Privacy Policy, which is hereby incorporated by reference. Please take the time to review our Privacy Policy. If you do not agree to any terms in these Terms of Service or the Privacy Policy, then please do not use our Service. We may change the Terms of Service. Please check back on our Website from time to time to check what changes have been made.</p>

    <h4>Permitted Uses</h4>
    <p>The Subscriber will not, and will not permit any third party to: (i) allow anyone other than a Subscriber to access or use the Service; (ii) use the Service in any way that is not expressly permitted by this Agreement, including, without limitation, reverse engineering, modifying, copying, distributing, or sub-licensing the Service, or introducing into the Service any software, virus, or code; or (iii) use the Service in violation of any applicable law or regulation.</p>

    <h4>User Account</h4>
    <p>Prior to using the Service, the Subscriber will be required to register for an account. During the registration process, Subscriber will select login credentials. Login credentials can only be used by the Subscriber for an authorized user(s) to whom the login credentials are assigned and cannot be shared with others. The Subscriber is solely responsible for the confidentiality and use of all login credentials for its account and those assigned to an authorized user, as well as for any use or misuse of the Service using Subscriber's or any authorized user's login credentials. The Subscriber will notify us immediately if they become aware of any loss, theft or unauthorized use of any logon credentials. We reserve the right to delete or change the logon credentials at any time and for any reason.</p>

    <h4>Access and Use</h4>
    <p>If you accept the terms and conditions contained in this Agreement, we will provide access to and use of the Service to you (the "Subscriber"). The Subscriber is solely responsible for any data or content uploaded or stored on the Service by the Subscriber. In no event shall EFT be responsible for the use or misuse of any data uploaded by the Subscriber or other third party. The Subscriber warrants and represents that it either owns or has the right to provide all such data uploaded or stored on the Service by the Subscriber. The Subscriber hereby grants EFT a non-exclusive, transferable, royalty-free license to use the data from the Subscriber to provide the Service and as otherwise described herein or in our Privacy Policy.</p>

    <h4>User Information</h4>
    <p>You warrant that you are authorized to access, use and transmit the data that you will enter and/or upload to this website, and that all information provided is accurate and currant to the best of your knowledge. You acknowledge that it is your responsibility to store and maintain your data on your own devices, including data that you enter and/or upload to this website as well as reports and data outputs generated by this website. This website is not equipped for long-term data storage. Furthermore, you acknowledge that JM Sustainability Manager Inc. cannot guarantee that data uploaded to this site will stay within Canada as some data processing may take place on sites in other countries.</p>

    <h4>Intellectual Property</h4>
    <p>All right, title, and interest in and to the Service, the Website and any information, data, software and other features contained therein, including all modifications, improvements and adaptations made thereto, and all proprietary rights in any of the foregoing (collectively, "EFT Property"), shall be and remain the sole and exclusive property of EFT.</p>

    <h4>Disclaimer</h4>
    <p>It is understood by you that the Service and the Website are offered for use on an “as-is” and “as-available” basis. We make no representations or warranties of any kind concerning the Service or the Website, whether express, implied, statutory, or other. You agree that, to the fullest extent possible, in no event shall we be liable to you for any direct, special, indirect, incidental, consequential, punitive, exemplary, or other losses, costs, expenses, or damages arising out of your use of the Service or the Website, even if we have been advised of the possibility of such losses, costs, expenses, or damages.</p>

    <h4>Email Disclaimer</h4>
    <p>Email communication including any information transmitted with it is intended only for the use of the addressee(s) and is confidential. If you are not an intended recipient or responsible for delivering the email to an intended recipient, any review, disclosure, reproduction or other use of any part of the email is strictly prohibited. If you receive an email communication in error, please notify JM Sustainability Managers immediately and delete the email.</p>


    <h4>No Warranty of Information</h4>
    <p>Information provided by JM Sustainability Mangers Inc. on this website is believed to be accurate, but we cannot guarantee it is complete or current at all times. The information provided on this website, including the information continued in any websites linked to this website, is provided “as is” without any warranty of any kind, either express or implied; including but not limited to the implied warranties of fitness for any particular purpose. JM Sustainability Managers Inc. can make changes to the information on this website, including these Terms of Use, at any time and without prior notice. However, JM Sustainability Managers Inc. disclaims any duty to or liability for any failure to update information on this website.</p>

    <h4>Limitation of Liability</h4>
    <p>Our aggregate liability for damages pursuant to any breach of these Terms of Service will not exceed the annual subscription fee paid by the Subscriber in respect of the period in which any claim arises.</p>

    <p>JM Sustainability Managers Inc. is not responsible in any manner for direct, indirect, special or consequential damages, however caused, arising out of your use of this website. JM Sustainability Managers Inc. assumes no responsibility for any damages sustained as a result of viruses or other destructive items that infect your computer equipment or other property in connection with your access to or use of this website or your downloading of any data, text, images, files or other materials from the ecoCity Footprint website.</p>

    <h4>Jurisdiction</h4>
    <p>The terms of use for the Service and website shall be governed by the provincial laws of British Columbia and the federal laws of Canada that apply in British Columbia.</p>

    <h4>Indemnification</h4>
    <p>The Subscriber will defend, indemnify and hold EFT harmless from any and all liabilities, costs, and expenses (including, without limitation, reasonable attorneys' fees) in connection with any third-party claim that any of the data uploaded by you to the Website: (i) infringes or misappropriates any third-party intellectual property rights, privacy or publicity rights, or any other rights; or (ii) violates any applicable laws, rules, or regulations. You will promptly notify us of any such claim. Failure to provide such notice shall not relieve you of your indemnity obligations to us. You will have control over the defence of any such claim, provided that (i) you do not make any admission of liability on behalf of EFT/JM Sustainability Managers Inc. or agree to any settlement that imposes a financial burden on same without our prior written consent; and (ii) EFT/JM Sustainability Managers Inc. shall have the right to participate in the defence of any such claim, at its own cost, with the legal adviser of its choice.</p>

      <h3>Privacy Policy</h3>

    <p>The EcoCity Footprint Tool is committed to protecting and respecting your privacy and your data.</p>

    <p>This privacy policy sets out how EFT uses and protects any information that you give EFT when you use this website.</p>

    <p>In order for us to deliver an effective service to you it is necessary for us to store and use personal information about you. We will treat all information supplied by you in accordance with this policy. All data will be stored in Canada.</p>

    <p>This policy only applies to this website and service and you should refer to the privacy policy of any third party website or service that you access in connection with this website, whether by means of hyperlink or otherwise.</p>

    <h4>Security</h4>
    <p>The Internet may not be a secure medium and you use this website at your own risk. You agree to assume the risks associated with the transmission or loss of any data, including the interception of data or information by third parties.</p>

    <p>Information supplied to us will be processed and temporarily stored on a secure server and may be used for a limited time in connection with future visits to this website to facilitate easy repeated use while uploading data and generating reports. Transaction processes will use data, encrypted using a Secured Socket Layer (“SSL”) session. You should ensure that your version of your web browser supports SSL.</p>

    <h4>Cookies</h4>
    <p>The website makes use of cookies. Cookies are data files that are stored on your computer’s hard drive and keep records of your visit to a website. They allow us to recognise you and tailor your visit to suit your individual preferences.</p>

    <p>By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.</p>

    <p>You may be able to configure your web browser to prevent the use of cookies although some functions or services may not be available. If any third party websites you can access through the website use cookies, we cannot access or control that.</p>

    <h4>Links to Third Party Sites</h4>
    <p>Links to other websites from this website are for convenience only. No endorsement of any third party products, services or information is expressed or implied by any information, material or content referred to, included on, or lined from or to this website. JM Sustainability Managers Inc. has no responsibility for the content of such third party sites and shall not be liable for any associated damages or injury.</p>


    <h4>Third parties and your information</h4>
    <p>We may disclose data that you upload to this site and related information generated by the Service to any member of our group, which means our subsidiaries, our ultimate holding company (JM Sustainability Managers Inc.) and its subsidiaries.</p>

    <p>We will, except where required by law, keep your information confidential and will not disclose any such information except where a disclosure is made at your request or with your consent.</p>

    <p>In some cases, we may wish to pass your data on to third parties when we are working with them in partnership on sustainability issues.</p>

    <p>You acknowledge and agree that some of the data that we process may amount to sensitive personal data. Your continued use of the website amounts to agreement that we may process such data. We, our subcontractors and agents will apply appropriate technical and organisational measures to prevent the unauthorised processing of personal data and protect against accidental loss of or damage to personal data.</p>

    <p>EFT does not otherwise sell, rent, or disclose any information to third parties for their marketing purposes.</p>

    <h4>Contact</h4>
    <p>If you have any questions, comments or requests regarding this Terms of Use or Privacy Policy you should address them to: <a href="mailto:ecocityfootprint@bcit.ca">ecocityfootprint@bcit.ca</a></p>
  </div>
);

export default TermsPage;
