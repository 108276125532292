import React from "react";
import './Tooltip.css';
import icon from '../../images/Question Mark Icon.svg';

class Tooltip extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
  }

  render() {
    return (
      <div className='tooltip' onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>
        <img alt='Tooltip icon' src={icon} />
        <div className={`tooltipTextContainer ${this.state.visible ? 'visible' : ''}`}>{this.props.text}</div>
      </div>
    )
  }

  handleMouseOver() {
    this.setState({visible: true});
  }

  handleMouseOut() {
    this.setState({visible: false});
  }
}

export default Tooltip;
