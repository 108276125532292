import React from 'react';
import './PopupMenu.css';

class PopupMenu extends React.Component {
  constructor(props) {
    super(props);

    this.containerRef = React.createRef();
  }

  setPosAndWidth(x, y, width) {
    const container = this.containerRef.current;
    if (container) {
      container.style.top = y + 'px';
      container.style.left = x + 'px';
      container.style.width = width + 'px';
    }
  }

  render() {
    return (
      <div className={`popupMenu ${this.props.className}`} ref={this.containerRef}>
        <div className='triangle'/>
        <div className='optionBox roundedShadowBox'>
          {this.props.options.map((option, index) =>
            <div className={`option ${option.selected ? 'selected' : ''}`}
                 key={index}
                 onClick={() => this.props.handleClick(option)}>
              {option.title}
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default PopupMenu;