import React from "react";
import './NewDatasetForm.css'
import {createNewDataset} from "../../actions";
import {connect} from "react-redux";

class NewDatasetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      failed: false,
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <form className='newDatasetForm' onSubmit={this.handleSubmit}>
        <h3>New dataset</h3>
        <p className='error'>{this.state.failed ? 'Failed to create dataset' : ''}</p>
        <label htmlFor='cloneSelector'>Copy data from:</label>
        <select id='cloneSelector' name='dataset_clone_from_id' onChange={this.handleChange}>
          <option value=''>None</option>
          {this.props.datasetList.map(dataset =>
            <option value={dataset.id} key={dataset.id}>
              {dataset.id}: {dataset.name} ({dataset.city}, {dataset.year})
            </option>
          )}
        </select>
        <input type='text'
               placeholder='Dataset name'
               name='dataset_name'
               onChange={this.handleChange}
               value={this.state.email}
               disabled={this.state.loading} />

        <input type='text'
               placeholder='City name'
               name='dataset_city_name'
               onChange={this.handleChange}
               value={this.state.password}
               disabled={this.state.loading} />

        <input type='submit' value='Create Dataset' disabled={this.state.loading} />
      </form>
    )
  }

  handleChange(event) {
    let changes = {};
    changes[event.target.name] = event.target.value;
    this.setState(changes);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      'dataset_name': this.state['dataset_name'],
      'dataset_city': this.state['dataset_city_name'],
      'dataset_clone_from_id': this.state['dataset_clone_from_id'] || '',
      'dataset_spec_id': this.props.datasetSpecs[this.props.datasetSpecs.length - 1].id
    };

    this.setState({
      loading: true,
      failed: false
    });

    this.props.createDataset(data)
      .then((json) => {
        this.setState({
          loading: false,
          failed: false
        });
        this.props.handleSuccess(json.dataset.id);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          failed: true
        });
      });
  }
}

const mapStateToProps = (state, ownProps) => ({
  datasetList: state.entities.datasetList.items,
  datasetSpecs: state.entities.datasetSpecs.items
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  createDataset: (data) => dispatch(createNewDataset(undefined, data))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewDatasetForm);
