import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware } from 'redux'

import rootReducer from './reducers'
import {requestUser, Pages, navigate, afterLoggedIn} from './actions'
import './index.css';
import App from './components/App';

const store = createStore(
  rootReducer,
  applyMiddleware(
    thunkMiddleware
  )
);

// Only for debugging
// console.log(store.getState());
// store.subscribe(() => console.log(store.getState()));

const match = document.location.hash.match(/#(.+?)(?:\?(.*))?$/);
let initialPage = Pages.HOME;
let initialParams = {};
if (match) {
  const page = match[1].toUpperCase();
  if (Pages[page]) {
    initialPage = page;
    if (match[2]) {
      initialParams = Object.fromEntries(
        match[2].split('&').map(
          c => c.split('=').map(decodeURIComponent)
        )
      );
    }
  }
}

store.dispatch(navigate(initialPage, initialParams, true));

store.dispatch(requestUser())
.then( () => store.dispatch(afterLoggedIn(initialPage, initialParams)) )
.catch(function () {
  // This is fine; we just wanted to know if the user was logged in initially
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
