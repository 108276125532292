import React from "react";
import './BarChartModule.css'
import '../../../node_modules/react-vis/dist/style.css';
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries
} from 'react-vis';

class BarChartModule extends React.Component {
  render() {
    const rows = this.props.data.rows.filter(row => !isNaN(Number(row.value)));

    return (
      <div className={`chartModule barChartModule roundedShadowBox ${this.props.className || ''}`}>
        <h3>{this.props.data.title}</h3>
        <div className='chartContainer'>
          <XYPlot margin={{bottom: 70, left: 70}} xType="ordinal" width={350} height={250}>
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis tickLabelAngle={-45} />
            <YAxis />
            <VerticalBarSeries
              data={ rows.map(row => ({x: row.label, y: Number(row.value)})) }
            />
          </XYPlot>
        </div>
      </div>
    )
  }
}

export default BarChartModule;
