import React from 'react';
import './Dashboard.css';
import AtAGlanceModule from './AtAGlanceModule';
import ChartModule from './ChartModule'
import BarChartModule from "./BarChartModule";
import ImageChartModule from "./ImageChartModule";
import PopupMenu from "../PopupMenu";
import SustainGapModule from "./SustainGapModule";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedNav: 'ecofootprint',
      selectedNavGroup: 'ecofootprint',
      datasetListHidden: true,
      exportStates: {}
    };
    this.handleClick = this.handleClick.bind(this);
    this.navClicked = this.navClicked.bind(this);
    this.datasetSelectorClicked = this.datasetSelectorClicked.bind(this);
    this.datasetOptionClicked = this.datasetOptionClicked.bind(this);
    this.deleteDatasetClicked = this.deleteDatasetClicked.bind(this);
    this.exportDatasetClicked = this.exportDatasetClicked.bind(this);
    this.selectedNavItemRef = React.createRef();
    this.navTriangleRef = React.createRef();
    this.popupMenuRef = React.createRef();
    this.datasetSelectorRef = React.createRef();
  }

  handleClick(event) {
    let element = event.target;
    while (element !== null) {
      if ((element.className || '').match('popupMenu') || element === this.datasetSelectorRef.current) {
        // Somewhere inside the popup menu was clicked; don't dismiss the popup menu
        return;
      }
      element = element.parentNode;
    }
    this.setState({
      datasetListHidden: true
    });
  }

  navClicked(itemName, groupName) {
    this.setState({
      selectedNav: itemName,
      selectedNavGroup: groupName,
    });
  }

  datasetSelectorClicked() {
    this.setState({
      datasetListHidden: !this.state.datasetListHidden
    });
  }

  datasetOptionClicked(option) {
    this.props.selectDataset(option.id);
  }

  deleteDatasetClicked() {
    this.props.deleteDataset(this.props.datasetId);
  }

  exportDatasetClicked() {
    const datasetId = this.props.datasetId;
    const exporting = 'Exporting...';
    if (this.state.exportStates[datasetId] === exporting) {
      return;
    }
    this.setState({
      exportStates: Object.assign({}, this.state.exportStates, {[datasetId]: exporting})
    });
    this.props.exportDataset(datasetId).then( (json) => {
      this.setState({
        exportStates: Object.assign({}, this.state.exportStates, {[datasetId]: undefined})
      });
      const a = document.createElement('a');
      a.download = `Dataset Export ${datasetId}.xlsx`;
      a.href = json['url'];
      a.click();
    }).catch( (error) => {
      this.setState({
        exportStates: Object.assign({}, this.state.exportStates, {[datasetId]: 'Export Failed'})
      });
      console.log(error);
    });
  }

  componentDidUpdate() {
    const item = this.selectedNavItemRef.current;
    const triangle = this.navTriangleRef.current;
    if (item && triangle) {
      const offset = item.offsetTop + (item.offsetHeight - triangle.offsetHeight) / 2;
      triangle.style.top = offset + 'px';
    }

    const popupMenu = this.popupMenuRef.current;
    const selector = this.datasetSelectorRef.current;
    if (popupMenu && selector) {
      const offsetY = selector.offsetTop + selector.offsetHeight + 7;
      popupMenu.setPosAndWidth(selector.offsetLeft, offsetY, selector.offsetWidth);
    }
  }

  static getModule(key, data, className) {
    const types = {
      'bar': BarChartModule,
      'donut': ChartModule,
      'atAGlance': AtAGlanceModule,
      'excel': ImageChartModule,
      'sustainGap': SustainGapModule
    };
    const Type = types[data.chartType];
    return <Type key={key} data={data} className={className} />;
  }

  render() {
    // TODO: This is way too much code before the return; should be refactored into components
    if (this.props.datasetOutput && this.props.datasetOutput.sectionGroups &&
      this.props.dataset && this.props.dataset.name && this.props.datasetList.items) {
      const sectionGroups = this.props.datasetOutput.sectionGroups.map(group => {
        const sections = group.sections;
        let chartModulePairs = [];

        if (group.isComplete) {
          let pair = [];
          let delta = 0;
          for (let i = 0; i < sections.length; i++) {
            const section = sections[i];
            let className = (i + delta) % 2 === 0 ? 'left' : 'right';
            if (className === 'left' || section.chartType === 'atAGlance') {
              pair = [];
              if (i === sections.length - 1 || section.chartType === 'atAGlance') {
                className = '';
                delta++;
              }
              chartModulePairs.push(pair);
            }
            pair.push(Dashboard.getModule(i, section, className));
          }
        } else {
          chartModulePairs.push([
            <div className='notEnoughData roundedShadowBox' key='noData'>
              Please fill in all data for this section to see results.
            </div>
          ]);
        }
        return {
          name: group.name,
          title: group.title,
          subtitle: group.subtitle,
          icon: group.icon,
          navGroup: group.navGroup,
          chartModulePairs
        }
      });
      // Nav items that their are icons for
      const withIcons = ['food', 'buildings', 'consumables', 'transportation', 'waste', 'water'];

      let navGroups = [];
      let navGroup = null;
      sectionGroups.forEach(function (sectionGroup) {
        if (navGroup === null || navGroup.id !== sectionGroup.navGroup) {
          navGroup = {
            id: sectionGroup.navGroup,
            name: sectionGroup.name,
            items: []
          };
          navGroups.push(navGroup);
        }
        navGroup.items.push({
          name: sectionGroup.name,
          title: sectionGroup.title,
          subtitle: sectionGroup.subtitle,
          icon: sectionGroup.icon
        });
      });

      return (
        <div className={`dashboard ${this.props.loading ? 'loading' : ''}`} onClick={this.handleClick}>
          <PopupMenu
            ref={this.popupMenuRef}
            className={this.state.datasetListHidden ? 'hidden' : ''}
            handleClick={this.datasetOptionClicked}
            options={this.props.datasetList.items.map(dataset => ({
              title: `${dataset.id}: ${dataset.name}`,
              id: dataset.id,
              selected: this.props.dataset.id === dataset.id
            }))}
          />
          <div className='datasetHeader roundedShadowBox'>
            <div className='identifier'>Dataset {this.props.dataset.id}</div>
            <div className='name'>{this.props.dataset.name}</div>
            <div className='exportDataset' onClick={this.exportDatasetClicked}>
              {this.state.exportStates[this.props.datasetId] || 'Export Dataset'}
            </div>
            <div className='deleteDataset' onClick={this.deleteDatasetClicked}>Delete Dataset</div>
            <div className='createNew' onClick={this.props.createDataset}>Create New</div>
            <div className='selector' ref={this.datasetSelectorRef} onClick={this.datasetSelectorClicked}>
              Select Another Dataset <span className='icon'/>
            </div>
          </div>
          <div className='dashboardMainContainer'>
            <div className='dashboardNav'>
              {navGroups.map(group =>
                <div className={`dashboardNavGroup roundedShadowBox ${group.name} ${group.name === this.state.selectedNavGroup ? 'selected' : ''}`} key={group.name}>
                  {group.items.map(item =>
                    <div key={item.name}
                         ref={item.name === this.state.selectedNav ? this.selectedNavItemRef : null}
                         className={`dashboardNavItem ${item.icon} ${item.name === this.state.selectedNav ? 'selected' : ''}`}
                         onClick={() => this.navClicked(item.name, group.name)}
                    >
                      {withIcons.includes(item.icon) &&
                        <div className='icon' />}
                      <div className='titleContainer'>
                        <div className='title'>{item.title}</div>
                        <div className='subtitle'>{item.subtitle}</div>
                      </div>
                      {group.items.length > 1 && <div className='downIcon'/>}
                    </div>
                  )}
                </div>
              )}
              <span className='triangle' ref={this.navTriangleRef} />
            </div>
            <div className='dashboardContent'>
              {sectionGroups.filter(group => group.chartModulePairs.length).map((group, index) =>
                <div className={`sectionGroup ${group.name === this.state.selectedNav ? 'selected' : ''}`} key={index}>
                  <h3>{group.title}</h3>
                  {group.chartModulePairs.map( (sectionPair, index) =>
                    <div className='chartModulePair' key={index}>
                      {sectionPair}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <p>{this.props.loading ? 'Loading...' : 'Failed to load.'}</p>
      );
    }
  }
}

export default Dashboard
