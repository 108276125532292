import React from 'react'
import './AuthForm.css'
import './ResetPasswordForm.css'
import { APIState } from '../../reducers'

class ResetPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: props.password || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const disableReset = [APIState.LOADING, APIState.COMPLETE].includes(this.props.apiState);
    return (
      <form className='resetPasswordForm authForm' onSubmit={this.handleSubmit}>
        <p>Please enter the new password you would like to use.</p>
        {this.props.apiState === APIState.FAILED &&
          <p className='error'>Failed to reset password.</p>
        }
        {this.props.apiState === APIState.COMPLETE &&
          <p className='success'>
            Your password has been reset. <span className='textLink' onClick={() => this.props.gotoLogin()}>Click to login</span>.
          </p>
        }
        <input type='text'
               name='email'
               autoComplete='username'
               defaultValue={this.props.email}
               className={'hidden'} />
        <input type='password'
               placeholder='New password'
               name='password'
               autoComplete='new-password'
               onChange={this.handleChange}
               value={this.state.password}
               disabled={disableReset} /><br />

        <input type='submit' value='Reset Password' disabled={disableReset} />
      </form>
    )
  }

  handleChange(event) {
    let changes = {};
    changes[event.target.name] = event.target.value;
    this.setState(changes);
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = {
      password: this.state.password,
      token: this.props.token,
    };
    this.props.onSubmit(data);
  }
}

export default ResetPasswordForm;
