import React from 'react';
import './Users.css'

const Users = ( {users, handleEditUser, handleDeleteUser} ) => (
  <div className='usersModule'>
    <h3>Users</h3>
    <table border={0}>
      <thead>
        <tr>
          <th>ID</th>
          <th>Email</th>
          <th>First name</th>
          <th>Last name</th>
          <th>Is admin</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {users.map( (user) =>
          <tr key={user.id}
              className={user.admin ? 'isAdmin' : ''}
              onClick={() => handleEditUser(user)}>
            <td>{user.id}</td>
            <td>{user.email}</td>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td className='adminCell'>{user.admin ? 'Yes' : 'No'}</td>
            <td className='deleteCell' onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleDeleteUser(user);
            }}>Delete</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default Users;
