import fetch from 'cross-fetch'

export let baseApiUrl = '';
if (process.env.NODE_ENV === 'development') {
  baseApiUrl = 'http://localhost:5000';
}

// Switch this value to restrict / unrestrict signup
export const RESTRICT_SIGNUP = true;

// Action types

const COMPLETE_SUFFIX = '_COMPLETE';
const FAILED_SUFFIX = '_FAILED';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_REQUEST_COMPLETE = 'LOGIN_REQUEST_COMPLETE';
export const LOGIN_REQUEST_FAILED = 'LOGIN_REQUEST_FAILED';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_REQUEST_COMPLETE = 'LOGOUT_REQUEST_COMPLETE';
export const LOGOUT_REQUEST_FAILED = 'LOGOUT_REQUEST_FAILED';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_REQUEST_COMPLETE = 'SIGNUP_REQUEST_COMPLETE';
export const SIGNUP_REQUEST_FAILED = 'SIGNUP_REQUEST_FAILED';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_REQUEST_COMPLETE = 'FORGOT_PASSWORD_REQUEST_COMPLETE';
export const FORGOT_PASSWORD_REQUEST_FAILED = 'FORGOT_PASSWORD_REQUEST_FAILED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_REQUEST_COMPLETE = 'RESET_PASSWORD_REQUEST_COMPLETE';
export const RESET_PASSWORD_REQUEST_FAILED = 'RESET_PASSWORD_REQUEST_FAILED';

export const USER_REQUEST = 'USER_REQUEST';
export const USER_REQUEST_COMPLETE = 'USER_REQUEST_COMPLETE';
export const USER_REQUEST_FAILED = 'USER_REQUEST_FAILED';

export const CREATE_DATASET_REQUEST = 'CREATE_DATASET_REQUEST';
export const CREATE_DATASET_REQUEST_COMPLETE = 'CREATE_DATASET_REQUEST_COMPLETE';
export const CREATE_DATASET_REQUEST_FAILED = 'CREATE_DATASET_REQUEST_FAILED';

export const DELETE_DATASET_REQUEST = 'DELETE_DATASET_REQUEST';
export const DELETE_DATASET_REQUEST_COMPLETE = 'DELETE_DATASET_REQUEST_COMPLETE';
export const DELETE_DATASET_REQUEST_FAILED = 'DELETE_DATASET_REQUEST_FAILED';

export const ADMIN_DELETE_USER_REQUEST = 'ADMIN_DELETE_USER_REQUEST';
export const ADMIN_DELETE_USER_REQUEST_FAILED = 'ADMIN_DELETE_USER_REQUEST_FAILED';
export const ADMIN_DELETE_USER_REQUEST_COMPLETE = 'ADMIN_DELETE_USER_REQUEST_COMPLETE';

export const SAVE_DATASET_REQUEST = 'SAVE_DATASET_REQUEST';
export const SAVE_DATASET_REQUEST_COMPLETE = 'SAVE_DATASET_REQUEST_COMPLETE';
export const SAVE_DATASET_REQUEST_FAILED = 'SAVE_DATASET_REQUEST_FAILED';

export const DATASET_SPECS_REQUEST = 'DATASET_SPEC_REQUEST';
export const DATASET_SPECS_REQUEST_COMPLETE = 'DATASET_SPEC_REQUEST_COMPLETE';
export const DATASET_SPECS_REQUEST_FAILED = 'DATASET_SPEC_REQUEST_FAILED';

export const DATASET_LIST_REQUEST = 'DATASET_LIST_REQUEST';
export const DATASET_LIST_REQUEST_COMPLETE = 'DATASET_LIST_REQUEST_COMPLETE';
export const DATASET_LIST_REQUEST_FAILED = 'DATASET_LIST_REQUEST_FAILED';

export const DATASET_REQUEST = 'DATASET_REQUEST';
export const DATASET_REQUEST_COMPLETE = 'DATASET_REQUEST_COMPLETE';
export const DATASET_REQUEST_FAILED = 'DATASET_REQUEST_FAILED';

export const DATASET_OUTPUT_REQUEST = 'DATASET_OUTPUT_REQUEST';
export const DATASET_OUTPUT_REQUEST_COMPLETE = 'DATASET_OUTPUT_REQUEST_COMPLETE';
export const DATASET_OUTPUT_REQUEST_FAILED = 'DATASET_OUTPUT_REQUEST_FAILED';

export const EXPORT_DATASET_REQUEST = 'EXPORT_DATASET_REQUEST';
export const EXPORT_DATASET_REQUEST_COMPLETE = 'EXPORT_DATASET_REQUEST_COMPLETE';
export const EXPORT_DATASET_REQUEST_FAILED = 'EXPORT_DATASET_REQUEST_FAILED';

export const WHITELIST_CODES_REQUEST = 'WHITELIST_CODES_REQUEST';
export const WHITELIST_CODES_REQUEST_COMPLETE = 'WHITELIST_CODES_REQUEST_COMPLETE';
export const WHITELIST_CODES_REQUEST_FAILED = 'WHITELIST_CODES_REQUEST_FAILED';

export const ADD_WHITELIST_CODES_REQUEST = 'ADD_WHITELIST_CODES_REQUEST';
export const ADD_WHITELIST_CODES_REQUEST_COMPLETE = 'ADD_WHITELIST_CODES_REQUEST_COMPLETE';
export const ADD_WHITELIST_CODES_REQUEST_FAILED = 'ADD_WHITELIST_CODES_REQUEST_FAILED';

export const DELETE_WHITELIST_CODE_REQUEST = 'DELETE_WHITELIST_CODE_REQUEST';
export const DELETE_WHITELIST_CODE_REQUEST_COMPLETE = 'DELETE_WHITELIST_CODE_REQUEST_COMPLETE';
export const DELETE_WHITELIST_CODE_REQUEST_FAILED = 'DELETE_WHITELIST_CODE_REQUEST_FAILED';

export const ADMIN_USERS_REQUEST = 'ADMIN_USERS_REQUEST';
export const ADMIN_USERS_REQUEST_COMPLETE = 'ADMIN_USERS_REQUEST_COMPLETE';
export const ADMIN_USERS_REQUEST_FAILED = 'ADMIN_USERS_REQUEST_FAILED';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_REQUEST_COMPLETE = 'EDIT_USER_REQUEST_COMPLETE';
export const EDIT_USER_REQUEST_FAILED = 'EDIT_USER_REQUEST_FAILED';

export const ADMIN_DATASET_LIST_REQUEST = 'ADMIN_DATASET_LIST_REQUEST';
export const ADMIN_DATASET_LIST_REQUEST_COMPLETE = 'ADMIN_DATASET_LIST_REQUEST_COMPLETE';
export const ADMIN_DATASET_LIST_REQUEST_FAILED = 'ADMIN_DATASET_LIST_REQUEST_FAILED';

export const DOWNLOAD_DATASET_REQUEST = 'DOWNLOAD_DATASET_REQUEST';
export const DOWNLOAD_DATASET_REQUEST_COMPLETE = 'DOWNLOAD_DATASET_REQUEST_COMPLETE';
export const DOWNLOAD_DATASET_REQUEST_FAILED = 'DOWNLOAD_DATASET_REQUEST_FAILED';

export const NAVIGATE = 'NAVIGATE';
export const SET_ACTIVE_DATASET = 'SET_ACTIVE_DATASET';

export const SET_HOME_AUTH_FORM = 'SET_HOME_AUTH_FORM';
export const LOGIN_FORM = 'login';
export const SIGNUP_FORM = 'signup';
export const FORGOT_PASSWORD_FORM = 'forgot_password';
export const RESET_PASSWORD_FORM = 'reset_password';
export const DEFAULT_AUTH_FORM = RESTRICT_SIGNUP ? LOGIN_FORM : SIGNUP_FORM;

export const SET_MODAL = 'SET_MODAL';
export const DISMISS_MODAL = 'DISMISS_MODAL';

export const SET_UNSAVED_DATA = 'SET_UNSAVED_DATA';
export const CLEAR_UNSAVED_DATA = 'CLEAR_UNSAVED_DATA';

// Constants

export const Pages = {
  HOME: 'HOME',
  QUESTIONNAIRE: 'QUESTIONNAIRE',
  DASHBOARD: 'DASHBOARD',
  TERMS: 'TERMS',
  ADMIN: 'ADMIN'
};

export const ModalTypes = {
  NEW_DATASET_FORM: 'NEW_DATASET_FORM',
  DELETE_DATASET_CONFIRM: 'DELETE_DATASET_CONFIRM',
  EDIT_SOURCES: 'EDIT_SOURCES',
  EDIT_USER: 'EDIT_USER',
};

// API helpers

function handleErrors(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

function urlFormEncode(data) {
  return Object.entries(data).map(
    entry => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`
  ).join('&');
}

function createApiAction(type, json, id) {
  return {
    type,
    json,
    id
  }
}

function apiRequest(method, endpoint, action) {
  return function(id, data) {
    // TODO: Make this more general when necessary
    const interpolatedEndpoint = endpoint.replace('{id}', id || '');
    
    let fetchParams = {
      credentials: 'include',
      method: method,
    };
    if (['POST', 'PUT'].indexOf(method) !== -1) {
      fetchParams = Object.assign({}, fetchParams, {
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: data ? urlFormEncode(data) : undefined,
      });
    }
    
    return function(dispatch) {
      dispatch(createApiAction(action, undefined, id));
      
      return fetch(`${baseApiUrl}${interpolatedEndpoint}`, fetchParams)
      .then(handleErrors)
      .then(response => response.json())
      .then(json => {
        dispatch(createApiAction(`${action}${COMPLETE_SUFFIX}`, json, id));
        return json;
      })
      .catch(function(error) {
        console.log(error);
        dispatch(createApiAction(`${action}${FAILED_SUFFIX}`, undefined, id));
        // Rethrow, so that promises waiting for this request know it failed
        throw(error);
      });
    };
  };
}

function getApiRequest(endpoint, action) {
  return apiRequest('GET', endpoint, action);
}

function postApiRequest(endpoint, action) {
  return apiRequest('POST', endpoint, action);
}

function putApiRequest(endpoint, action) {
  return apiRequest('PUT', endpoint, action);
}

function deleteApiRequest(endpoint, action) {
  return apiRequest('DELETE', endpoint, action);
}

// Public action (thunk) creators

export const doLogin = postApiRequest('/login', LOGIN_REQUEST);
export const doLogout = postApiRequest('/logout', LOGOUT_REQUEST);
export const doSignup = postApiRequest('/signup', SIGNUP_REQUEST);
export const doForgotPassword = postApiRequest('/forgot_password', FORGOT_PASSWORD_REQUEST);
export const doResetPassword = postApiRequest('/reset_password', RESET_PASSWORD_REQUEST);
export const createNewDataset = postApiRequest('/datasets', CREATE_DATASET_REQUEST);
export const addWhitelistCodes = postApiRequest('/admin/whitelist_codes', ADD_WHITELIST_CODES_REQUEST);
export const downloadDataset = postApiRequest('/admin/datasets/{id}/download', DOWNLOAD_DATASET_REQUEST);
export const exportDataset = postApiRequest('/datasets/{id}/export', EXPORT_DATASET_REQUEST);

export const deleteDataset = deleteApiRequest('/datasets/{id}', DELETE_DATASET_REQUEST);
export const deleteWhitelistCode = deleteApiRequest('/admin/whitelist_codes/{id}', DELETE_WHITELIST_CODE_REQUEST);
export const deleteUser = deleteApiRequest('/admin/users/{id}', ADMIN_DELETE_USER_REQUEST);

export const saveDataset = putApiRequest('/datasets/{id}', SAVE_DATASET_REQUEST);
export const editUserRequest = putApiRequest('/admin/users/{id}', EDIT_USER_REQUEST);

export const requestUser = getApiRequest('/users/me', USER_REQUEST);
export const requestAdminUsers = getApiRequest('/admin/users', ADMIN_USERS_REQUEST);
export const requestDatasetSpecs = getApiRequest('/dataset_specs', DATASET_SPECS_REQUEST);
export const requestDatasetList = getApiRequest('/datasets', DATASET_LIST_REQUEST);
export const requestUserDatasetList = getApiRequest('/admin/users/{id}/datasets', ADMIN_DATASET_LIST_REQUEST);
export const requestDataset = getApiRequest('/datasets/{id}', DATASET_REQUEST);
export const requestDatasetOutput = getApiRequest('/datasets/{id}/output', DATASET_OUTPUT_REQUEST);
export const requestWhitelistCodes = getApiRequest('/admin/whitelist_codes', WHITELIST_CODES_REQUEST);

export function confirmUnsavedData(state) {
  if (!state.unsavedData) {
    return true;
  }
  return window.confirm("You have unsaved changes, are you sure you want to leave this page?");
}

export function navigate(page, params, force) {
  return function(dispatch, getState) {
    const action = {
      type: NAVIGATE,
      page,
      params,
    };

    let paramsSuffix = '';
    if (params && Object.keys(params).length > 0) {
      paramsSuffix = '?' + Object.keys(params).map(key =>
        encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
      ).join('&');
    }
    let newHash = '#' + page.toLowerCase() + paramsSuffix;
    if (newHash === document.location.hash && page === getState().nav.page && !force) {
      return null;
    }

    if (!confirmUnsavedData(getState())) {
      return null;
    }

    document.location.hash = newHash;
    dispatch(clearUnsavedData());
    
    switch(page) {
      case Pages.DASHBOARD:
        if (getState().loggedIn) {
          const id = getState().activeDatasetId;
          dispatch(requestDatasetList())
            .catch( (error) => console.log(`Couldn't load dataset list: ${error}`));
          dispatch(requestDatasetOutput(id))
            .catch( (error) => console.log(`Couldn't load dataset output: ${error}`));
          dispatch(requestDataset(id))
            .catch( (error) => console.log(`Couldn't load dataset: ${error}`));
          dispatch(requestDatasetSpecs())
            .catch( (error) => console.log(`Couldn't load dataset specs: ${error}`));
          return dispatch(action);
        }
        break;
      
      case Pages.QUESTIONNAIRE:
        if (getState().loggedIn) {
          dispatch(requestDatasetSpecs())
          .then(function() {
            if (getState().activeDatasetId === null) {
              const specs = getState().entities.datasetSpecs.items;
              let data = {dataset_spec_id: specs[specs.length - 1].id};
              dispatch(createNewDataset('', data))
              .then( () => {
                const list = getState().entities.datasetList.items;
                return dispatch(setActiveDataset(list[list.length - 1].id))
              })
              .then( () => dispatch(requestDataset(getState().activeDatasetId)) );
            } else {
              dispatch(requestDataset(getState().activeDatasetId));
            }
            
            return dispatch(action);
          });
        }
        break;

      case Pages.ADMIN:
        if (getState().loggedIn && getState().user.admin) {
          dispatch(requestWhitelistCodes());
          dispatch(requestAdminUsers());
          return dispatch(action);
        }
        break;
        
      default:
        return dispatch(action);
    }
  }
}

export function setActiveDataset(id) {
  return {
    type: SET_ACTIVE_DATASET,
    id
  }
}

export function setActiveDatasetToNewest() {
  return function (dispatch, getState) {
    const datasets = getState().entities.datasetList.items;
    const id = datasets && datasets.length ? datasets[datasets.length - 1].id : null;
    return dispatch(setActiveDataset(id));
  }
}

export function setModal(modalType, data) {
  return {
    type: SET_MODAL,
    modalType: modalType,
    data: data
  }
}

export function editUserModal(user) {
  return function (dispatch) {
    if (user && user.id) {
      dispatch(requestUserDatasetList(user.id));
    }
    return dispatch(setModal(ModalTypes.EDIT_USER, {user: user}));
  }
}

export function dismissModal() {
  return { type: DISMISS_MODAL }
}

export function setUnsavedData() {
  return function (dispatch) {
    window.onbeforeunload = function () {
      return true;
    };
    return dispatch({ type: SET_UNSAVED_DATA });
  }
}

export function clearUnsavedData() {
  return function (dispatch) {
    window.onbeforeunload = null;
    return dispatch({ type: CLEAR_UNSAVED_DATA });
  }
}

export function setHomeAuthForm(value) {
  return navigate(Pages.HOME, { authForm: value });
}

export function toggleHomeAuthForm() {
  return function (dispatch, getState) {
    const authForm = getState().nav.params.authForm;
    if (!getState().disableSignup || authForm !== LOGIN_FORM || !authForm) {
      const value = authForm !== LOGIN_FORM ? LOGIN_FORM : SIGNUP_FORM;
      return dispatch(setHomeAuthForm(value))
    }
    return null;
  }
}

export function afterLoggedIn(initialPage, initialParams) {
  return function (dispatch, getState) {
    return dispatch(requestDatasetList())
    .then(function () {
      dispatch(setActiveDatasetToNewest());
      const activeId = getState().activeDatasetId;
      const activeDataset = activeId && getState().entities.datasetList.items.find(d => d.id === activeId);
      if (initialPage) {
        dispatch(navigate(initialPage, initialParams))
      } else if (activeDataset && activeDataset.version !== 0) {
        dispatch(navigate(Pages.DASHBOARD));
      } else {
        dispatch(navigate(Pages.QUESTIONNAIRE));
      }
    });
  }
}
