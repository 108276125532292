import React from 'react'
import './SignupForm.css'
import './AuthForm.css'

class SignupForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.firstName || '',
      lastName: props.lastName || '',
      email: props.email || '',
      password: props.password || '',
      whitelistCode: props.whitelistCode || '',
      agreedTerms: props.agreedTerms || false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    return (
      <form className='signupForm authForm' onSubmit={this.handleSubmit}>
        <p className='error'>{this.props.failed ? 'Failed to signup' : ''}</p>

        <input type='text'
               name='firstName'
               id='signupFirstName'
               autoComplete='firstName'
               placeholder='First Name'
               onChange={this.handleChange}
               value={this.state.firstName}
               disabled={this.props.loading}/>

        <input type='text'
               name='lastName'
               id='signupLastName'
               autoComplete='lastName'
               placeholder='Last Name'
               onChange={this.handleChange}
               value={this.state.lastName}
               disabled={this.props.loading}/>

        <input type='text'
               name='email'
               id='signupEmail'
               autoComplete='email'
               placeholder='Email'
               onChange={this.handleChange}
               value={this.state.email}
               disabled={this.props.loading}/>

        <input type='password'
               name='password'
               id='signupPassword'
               autoComplete='new-password'
               placeholder='Password'
               onChange={this.handleChange}
               value={this.state.password}
               disabled={this.props.loading}/>

        {this.props.restrictSignup &&
        <input type='text'
               name='whitelistCode'
               id='signupCode'
               placeholder='Signup Code'
               onChange={this.handleChange}
               value={this.state.whitelistCode}
               disabled={this.props.loading}/>
        }

        <div className='tocContainer'>
          <input type='checkbox'
                 name='agreedTerms'
                 id='agreedTerms'
                 onChange={this.handleChange}
                 checked={this.state.agreedTerms}
                 disabled={this.props.loading}/>
          <label htmlFor='agreedTerms'> I have read and agree to the </label>
          <span className='link' onClick={this.props.showTerms}>Terms and Privacy Policy</span>.
        </div>

        <input type='submit' value='Sign Up' disabled={this.props.loading}/>
      </form>
    )
  }

  handleChange(event) {
    let changes = {};
    const target = event.target;
		changes[event.target.name] = target.type === 'checkbox' ? target.checked : target.value;
    this.setState(changes);
  }

  handleSubmit(event) {
    event.preventDefault();
    let data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      whitelist_code: this.state.whitelistCode,
      agreed_terms: this.state.agreedTerms ? 'true' : ''
    };
    this.props.onSubmit(data);
  }
}

export default SignupForm;
