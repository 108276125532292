import { connect } from 'react-redux'
import {
  doSignup,
  doLogin,
  requestUser,
  afterLoggedIn,
  navigate,
  Pages,
  setHomeAuthForm, doForgotPassword, doResetPassword, toggleHomeAuthForm, DEFAULT_AUTH_FORM
} from '../actions'
import Home from '../components/home/Home'

const mapStateToProps = (state, ownProps) => ({
  fetchingUser: state.user.fetching,
  authForm: state.nav.params.authForm || DEFAULT_AUTH_FORM,
  passwordResetToken: state.nav.params.token || '',
  email: state.nav.params.email || '',
  disableSignup: state.disableSignup,
  datasetList: state.entities.datasetList,
  signingUp: state.signingUp,
  signupFailed: state.signupFailed,
  loggedIn: state.loggedIn,
  loggingIn: state.loggingIn,
  loginFailed: state.loginFailed,
  firstName: state.user.firstName,
  forgotPassword: state.forgotPassword,
  resetPassword: state.resetPassword,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  doSignup: function (data) {
    dispatch(doSignup('', data))
    .then( () => dispatch(requestUser()) )
    .then( () => dispatch(afterLoggedIn()) )
    .catch(function () {
      // This is already handled
    });
  },
  doLogin: function (data) {
    dispatch(doLogin('', data))
    .then( () => dispatch(requestUser()) )
    .then( () => dispatch(afterLoggedIn()) )
    .catch(function () {
      // This is already handled
    });
  },
  doForgotPassword: function (data) {
    dispatch(doForgotPassword('', data))
      .catch(function () {
        // This is already handled
      });
  },
  doResetPassword: function (data) {
    dispatch(doResetPassword('', data))
      .catch(function () {
        // This is already handled
      });
  },
  setAuthForm: function (value) {
    dispatch(setHomeAuthForm(value));
  },
  toggleAuthForm: function () {
    dispatch(toggleHomeAuthForm());
  },
  showTerms: function () {
    dispatch(navigate(Pages.TERMS))
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home)
