import React from 'react'
import './Questionnaire.css'
import InputItem from "./InputItem";
import Tooltip from "./Tooltip";
import PageSelector from "./PageSelector";

class Questionnaire extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedPage: props.datasetSpec && props.datasetSpec.pages && props.datasetSpec.pages[0].name,
      showAssumptions: false
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.discardChanges = this.discardChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSources = this.handleSources.bind(this);
    this.toggleAssumptions = this.toggleAssumptions.bind(this);
    this.navigateToPage = this.navigateToPage.bind(this);
    this.navigateToNextPage = this.navigateToNextPage.bind(this);

    this.containerRef = React.createRef();
  }

  static undefFallback(value, fallback) {
    return value !== undefined ? value : fallback;
  }
  
  rowName(specSectionId, rowIndex) {
    if (!this.props.loading && this.props.dataset) {
      const sectionId = this.props.dataset.sections[specSectionId].id;
      return `row_${sectionId}_${rowIndex}`;
    }
    return '';
  }
  
  rowValue(sectionId, rowIndex) {
    if (this.state[this.rowName(sectionId, rowIndex)] !== undefined) {
      return this.state[this.rowName(sectionId, rowIndex)];
    }
    if (!this.props.loading && this.props.dataset) {
      return this.props.dataset.sections[sectionId].rows[rowIndex]['value'] || '';
    }
    return '';
  }

  rowSources(sectionId, rowIndex) {
    let row = {};
    if (!this.props.loading && this.props.dataset) {
      row = this.props.dataset.sections[sectionId].rows[rowIndex];
    }
    const name = this.rowName(sectionId, rowIndex);
    return {
      'source': Questionnaire.undefFallback(this.state[`${name}_source`], row['source'] || ''),
      'notationKey': Questionnaire.undefFallback(this.state[`${name}_notation_key`], row['notationKey'] || ''),
      'quality': Questionnaire.undefFallback(this.state[`${name}_quality`], row['quality'] || ''),
    };
  }

  rowHasSources(sectionId, rowIndex) {
    const sources = this.rowSources(sectionId, rowIndex);
    return sources['source'] || sources['notationKey'] || sources['quality'];
  }


  selectedPage() {
    return this.state.selectedPage ||
      (this.props.datasetSpec && this.props.datasetSpec.pages && this.props.datasetSpec.pages[0].name);
  }

  navigateToPage(page) {
    this.setState({
      selectedPage: page.name,
      showAssumptions: false,
    });
  }

  navigateToNextPage() {
    const selectedPage = this.selectedPage();
    if (selectedPage) {
      const index = this.props.datasetSpec.pages.findIndex(p => p.name === selectedPage);
      if (index !== -1) {
        if (index + 1 < this.props.datasetSpec.pages.length) {
          this.navigateToPage(this.props.datasetSpec.pages[index + 1]);
          if (this.containerRef.current) {
            window.scrollTo(0, this.containerRef.current.offsetTop);
          }
        } else {
          this.props.gotoDashboard();
          window.scrollTo(0, 0);
        }
      }
    }
  }

  pageIsComplete() {
    if (this.loading) {
      return false;
    }
    let complete = true;
    const selectedPage = this.selectedPage();
    const page = this.props.datasetSpec.pages.find(p => p.name === selectedPage);
    page.sectionGroups.forEach(sectionGroup => {
      if (sectionGroup.type === 'assumptions') {
        return;
      }
      sectionGroup.sections.forEach(section =>
        section.rows.forEach(row => {
          if (row.label && !(row.defaultValue || this.rowValue(section.id, row.index))) {
            complete = false;
          }
        })
      )
    });
    return complete;
  }
  
  render() {
    const selectedPage = this.selectedPage();
    let selectedPageTitle = '';
    let selectedPageIntroText = '';
    if (selectedPage && !this.props.loading) {
      const page = this.props.datasetSpec.pages.find(p => p.name === selectedPage);
      selectedPageTitle = page ? page.title : '';
      selectedPageIntroText = page ? page.introText : '';
    }

    if (this.props.loading || (this.props.datasetSpec && this.props.dataset)) {
      return (
        <div className='questionnaire'>
          <h2>
            <span className='icon' />
            <span className='text'>To understand your community’s urban metabolism, ecological and <br />carbon footprint, enter community-wide data in the following categories.</span>
          </h2>
          <div className='mainContainer roundedShadowBox' ref={this.containerRef}>
            {this.props.loading ?
              <p className='loadingIndicator'>Loading...</p>
              :
              <div>
                <PageSelector pages={this.props.datasetSpec.pages}
                              selectedPage={selectedPage}
                              handleClick={this.navigateToPage}
                />
                <div className='explainerText'>
                  <p>{selectedPageIntroText}</p>
                </div>
                <form onSubmit={() => false}>
                  {this.props.datasetSpec.pages.map((page, index) =>
                    <div key={index} className={`inputPage ${selectedPage === page.name ? 'selected' : ''} ${this.state.showAssumptions ? 'showAssumptions' : ''}`}>
                      {page.sectionGroups.map((group, index) =>
                        <div key={index} className={`inputGroup ${group.type}`}>
                          <h3>
                            <span className='title'>
                              {group.title}
                              {group.type === 'assumptions' &&
                                <span className='showHideAssumptions' onClick={this.toggleAssumptions}>
                                  {this.state.showAssumptions ? ' hide' : ' show'}
                                </span>
                              }
                            </span>
                            <span className='subtitle'>{group.subtitle} {group.description && <Tooltip text={group.description} />}</span>
                          </h3>
                          {group.name === 'city' && (
                            <div>
                              <div className='inputSectionContainer'>
                                <h4>Dataset</h4>
                                <div className='inputSectionItems metadataItems'>
                                  <InputItem name='dataset_name'
                                             className='datasetNameItem'
                                             label='Dataset Name'
                                             allowText={true}
                                             value={Questionnaire.undefFallback(this.state['dataset_name'], this.props.dataset.name)}
                                             handleChange={this.handleChange}
                                             handleKeyDown={this.handleKeyDown}
                                  />
                                  <InputItem name='dataset_year'
                                             label='Study Year'
                                             disableThousandCammas={true}
                                             tooltip='It is important to carefully consider your study year before beginning to collect and enter data. Select the most recent year possible, that you have sufficient data to create your assessment.'
                                             value={Questionnaire.undefFallback(this.state['dataset_year'], this.props.dataset.year)}
                                             handleChange={this.handleChange}
                                             handleKeyDown={this.handleKeyDown}
                                  />
                                </div>
                              </div>
                              <div className='inputSectionContainer'>
                                <h4>City</h4>
                                <div className='inputSectionItems'>
                                  <InputItem name='dataset_city'
                                             label='City Name'
                                             allowText={true}
                                             value={Questionnaire.undefFallback(this.state['dataset_city'], this.props.dataset.city)}
                                             handleChange={this.handleChange}
                                             handleKeyDown={this.handleKeyDown}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                          {group.sections.map((section, index) =>
                            <div key={index} className='inputSectionContainer'>
                              <h4>{section.title} {section.tooltip && <Tooltip text={section.tooltip} />}</h4>
                              <div className='inputSectionItems'>
                                {section.rows.filter(row => row.label).map(row =>
                                  <InputItem key={this.rowName(section.id, row.index)}
                                             name={this.rowName(section.id, row.index)}
                                             label={row.label}
                                             placeholder={row.defaultValue}
                                             value={this.rowValue(section.id, row.index)}
                                             unit={row.unit}
                                             hasSources={this.rowHasSources(section.id, row.index)}
                                             handleChange={this.handleChange}
                                             handleSources={() => this.handleSources(section.id, row.index)}
                                             handleKeyDown={this.handleKeyDown}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  <div className='submitContainer'>
                    <p>{this.pageIsComplete() &&
                      <span>Great job! You’ve filled out the {selectedPageTitle} <br/>section of your new dataset.</span>
                    }</p>
                    <div>
                      {this.props.unsavedData &&
                        <input type='submit' value='Discard Changes' className={'discardChangesButton'}
                               onClick={this.discardChanges}/>
                      }
                      <input type='submit' value='Save and Continue' disabled={this.props.dataset.saving}
                             onClick={this.handleSubmit}/>
                    </div>
                  </div>
                </form>
              </div>
            }
          </div>
        </div>
      );
    } else {
      return (
        <p>Failed to load.</p>
      );
    }
  }
	
  handleChange(event) {
    const target = event.target;
  	let changes = {};
  	changes[target.name] = target.value;
  	this.setState(changes);
  	this.props.setUnsavedData();
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      const focusableElements = document.querySelectorAll('input, select');
      const index = Array.prototype.indexOf.call(focusableElements, document.activeElement);
      let element = null;
      if (event.shiftKey) {
        element = focusableElements[index - 1];
      } else {
        element = focusableElements[index + 1];
      }
      if (element && element.focus) {
        element.focus();
      }

      event.preventDefault()
    }
  }

  handleSources(sectionId, rowIndex) {
    const name = this.rowName(sectionId, rowIndex);
    const handleSave = (data) => {
      this.setState({
        [name + '_source']: data.source,
        [name + '_notation_key']: data.notationKey,
        [name + '_quality']: data.quality,
      });
      this.props.dismissModal();
      this.props.setUnsavedData();
    };
    const sourcesData = this.rowSources(sectionId, rowIndex);
    const data = {
      source: sourcesData.source,
      notationKey: sourcesData.notationKey,
      quality: sourcesData.quality,
      handleSave
    };
    this.props.editSources(data);
  }

  toggleAssumptions() {
    this.setState({showAssumptions: !this.state.showAssumptions});
  }

  isValidDataKey(key) {
    return key.indexOf('row_') === 0 || key.indexOf('dataset_') === 0;
  }
  
  handleSubmit(event) {
    event.preventDefault();

    if (!this.pageIsComplete()) {
      if (!window.confirm("Not all required data is filled in. This data is required to calculate your results. Are you sure you want to continue?")) {
        return;
      }
    }

    let state = this.state;
    let data = {};

    // Set default values for all drop down menus
    this.props.datasetSpec.pages.forEach(page =>
      page.sectionGroups.forEach(group =>
        group.sections.forEach(section =>
          section.rows.forEach(row => {
            const match = row.defaultValue.match(/\[(.+)]/);
            if (match) {
              const key = this.rowName(section.id, row.index);
              const options = match[1].split('/');
              data[key] = options[0];
            }
          })
        )
      )
    );

    Object.keys(this.state).filter(this.isValidDataKey).forEach(function (rowKey) {
      data[rowKey] = state[rowKey];
    });
    this.props.saveDataset(this.props.dataset.id, data)
      .then(this.props.clearUnsavedData())
      .then(this.navigateToNextPage)
      .catch( (e) => window.alert(`Couldn't save dataset; please try again! Error: ${e}`) );
  }

  discardChanges(event) {
    event.preventDefault();
    if (window.confirm("Are you sure you want to discard your changes?")) {
      let data = {};
      Object.keys(this.state).filter(this.isValidDataKey).forEach(function (rowKey) {
        data[rowKey] = undefined;
      });
      this.props.clearUnsavedData();
      this.setState(data);
    }
  }
}

export default Questionnaire;
