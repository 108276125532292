import { connect } from 'react-redux'
import Questionnaire from '../components/questionnaire/Questionnaire'
import {
  clearUnsavedData,
  dismissModal,
  ModalTypes,
  navigate,
  Pages,
  saveDataset,
  setModal,
  setUnsavedData
} from '../actions'

function mapStateToProps(state, ownProps) {
  const loadingList = state.entities.datasetList.fetching;
  const activeId = state.activeDatasetId;
  const dataset = state.entities.datasets[activeId];
  const loadingSpecs = state.entities.datasetSpecs.fetching;
  const loadingDataset = !dataset || dataset.fetching;
  const unsavedData =  state.unsavedData;

  const specs = state.entities.datasetSpecs.items;
  let datasetSpec = null;
  if (specs && dataset) {
    datasetSpec = specs.find(spec => spec.id === dataset.specId);
  }
  
  const loading = loadingList || loadingDataset || loadingSpecs || !datasetSpec;
  
  return {
    loading,
    unsavedData,
    datasetSpec,
    dataset
  };
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  saveDataset: (id, data) => dispatch(saveDataset(id, data)),
  clearUnsavedData: () => dispatch(clearUnsavedData()),
  setUnsavedData: () => dispatch(setUnsavedData()),
  gotoDashboard: () => dispatch(navigate(Pages.DASHBOARD)),
  editSources: (data) => dispatch(setModal(ModalTypes.EDIT_SOURCES, data)),
  dismissModal: () => dispatch(dismissModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaire);
