import { connect } from 'react-redux'
import {navigate, doLogout, Pages, LOGIN_FORM, toggleHomeAuthForm} from '../actions'
import NavHeader from '../components/NavHeader'

const HOME = 'HOME';
const QUESTIONNAIRE = 'QUESTIONNAIRE';
const DASHBOARD = 'DASHBOARD';
const LOGOUT = 'LOGOUT';
const RIGHT_BUTTON = 'RIGHT_BUTTON';
const ADMIN = 'ADMIN';

function mapStateToProps(state, ownProps) {

  let items = [];
  let rightButtonTitle = state.homeAuthForm !== LOGIN_FORM || state.disableSignup ? 'Login' : 'Create an Account';
  
  if (state.loggedIn) {
    items = [
      {title: 'Datasets', type: QUESTIONNAIRE},
      {title: 'Dashboard', type: DASHBOARD},
      {title: 'Logout', type: LOGOUT}
      ];
    if (state.user.admin) {
      items = [
        {title: 'Admin', type: ADMIN},
        ...items
      ]
    }
    rightButtonTitle = `${state.user.firstName} ${state.user.lastName}`;
  }
  
  return {
    activePage: state.nav.page,
    items,
    logoAction: HOME,
    rightButtonTitle,
    rightButtonAction: RIGHT_BUTTON
  }
}

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: function (type, data) {
    switch (type) {
      case HOME:
        dispatch(navigate(Pages.HOME));
        return;
      case QUESTIONNAIRE:
        dispatch(navigate(Pages.QUESTIONNAIRE));
        return;
      case DASHBOARD:
        dispatch(navigate(Pages.DASHBOARD));
        return;
      case LOGOUT:
        dispatch(doLogout()).then( () => (
          dispatch(navigate(Pages.HOME))
        ));
        return;
      case ADMIN:
        dispatch(navigate(Pages.ADMIN));
        return;
      case RIGHT_BUTTON:
        dispatch(navigate(Pages.HOME));
        dispatch(toggleHomeAuthForm());
        return;
      default:
        return;
    }
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavHeader)
