import React from 'react';
import './Admin.css';
import LoadingComponent from "./LoadingComponent";
import WhitelistCodes from "./WhitelistCodes";
import Users from "./Users";

class Admin extends React.Component {
  render() {
    return (
      <div className='admin'>
        <h2>Admin</h2>
        <LoadingComponent loading={this.props.whitelistCodes.loading || !this.props.whitelistCodes.items}>
          <WhitelistCodes codes={this.props.whitelistCodes.items} handleAdd={this.props.addWhitelistCodes} />
        </LoadingComponent>
        <LoadingComponent loading={this.props.users.loading || !this.props.users.items}>
          <Users
            users={this.props.users.items}
            handleEditUser={this.props.handleEditUser}
            handleDeleteUser={this.props.handleDeleteUser}
          />
        </LoadingComponent>
      </div>
    );
  }
}

export default Admin;
