import {
  USER_REQUEST,
  USER_REQUEST_COMPLETE,
  USER_REQUEST_FAILED,
  LOGOUT_REQUEST
} from '../actions'

function user(state = {
  fetching: false,
  firstName: null,
  lastName: null,
  email: null,
  admin: false
}, action) {
  switch (action.type) {
    case USER_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
      
    case USER_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
      
    case USER_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        fetching: false,
        firstName: action.json.user.first_name,
        lastName: action.json.user.last_name,
        email: action.json.user.email,
        admin: action.json.user.admin
      });
      
    case LOGOUT_REQUEST:
      return Object.assign({}, state, {
        firstName: null,
        lastName: null,
        email: null,
        admin: false
      });
      
    default:
      return state
  }
}

export default user;