import {
  ADMIN_DATASET_LIST_REQUEST, ADMIN_DATASET_LIST_REQUEST_COMPLETE, ADMIN_DATASET_LIST_REQUEST_FAILED,
  CREATE_DATASET_REQUEST_COMPLETE,
  DATASET_LIST_REQUEST,
  DATASET_LIST_REQUEST_COMPLETE,
  DATASET_LIST_REQUEST_FAILED,
  DELETE_DATASET_REQUEST_COMPLETE,
} from '../actions'

const datasetSummary = (dataset) => ({
  id: dataset.id,
  version: dataset.version,
  name: dataset.name,
  city: dataset.city,
  year: dataset.year,
  specId: dataset.spec_id,
  owner: dataset.owner
});

export function datasetList(state = {
  fetching: false,
  items: null
}, action) {
  switch (action.type) {
    case DATASET_LIST_REQUEST:
    case ADMIN_DATASET_LIST_REQUEST:
      return Object.assign({}, state, {
        fetching: true
      });
    case DATASET_LIST_REQUEST_FAILED:
    case ADMIN_DATASET_LIST_REQUEST_FAILED:
      return Object.assign({}, state, {
        fetching: false
      });
    case DATASET_LIST_REQUEST_COMPLETE:
    case ADMIN_DATASET_LIST_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        fetching: false,
        items: action.json.datasets.map(datasetSummary)
      });
    case CREATE_DATASET_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        items: [...(state.items || []), datasetSummary(action.json.dataset)]
      });
    case DELETE_DATASET_REQUEST_COMPLETE:
      if (!state.items) {
        return state;
      }
      return Object.assign({}, state, {
        items: state.items.filter(d => d.id !== action.id)
      });
    default:
      return state
  }
}

export function userDatasetLists(state = {}, action) {
  switch (action.type) {
    case ADMIN_DATASET_LIST_REQUEST:
    case ADMIN_DATASET_LIST_REQUEST_FAILED:
    case ADMIN_DATASET_LIST_REQUEST_COMPLETE:
      return Object.assign({}, state, {
        [action.id]: datasetList(state[action.id], action)
      });
    default:
      return state
  }
}
