import './Modal.css'
import React from "react";
import { ModalTypes } from '../../actions'
import NewDatasetForm from "./NewDatasetForm";
import DeleteDatasetConfirm from "./DeleteDatasetConfirm";
import EditSources from "./EditSources";
import EditUser from "./EditUser";

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.onContainerClick = this.onContainerClick.bind(this);
  }

  render() {
    const typeMap = {
      [ModalTypes.NEW_DATASET_FORM]: NewDatasetForm,
      [ModalTypes.DELETE_DATASET_CONFIRM]: DeleteDatasetConfirm,
      [ModalTypes.EDIT_SOURCES]: EditSources,
      [ModalTypes.EDIT_USER]: EditUser,
    };
    const Type = typeMap[this.props.type];

    return (
      <div className='modalContainer' onClick={this.onContainerClick}>
        <div className='modalContent roundedShadowBox'>
          {<Type {...this.props.data}/>}
        </div>
      </div>
    )
  }

  onContainerClick(event) {
    if (this.props.handleDismiss && event.target.className === 'modalContainer') {
      this.props.handleDismiss();
    }
  }
}

export default Modal;