import { connect } from 'react-redux'
import Admin from "../components/admin/Admin";
import {addWhitelistCodes, deleteUser, editUserModal} from "../actions";

const handleDeleteUser = (user, dispatch) => {
  if (window.confirm("Really delete user?")) {
    dispatch(deleteUser(user.id)).catch(() => {});
  }
};

const mapStateToProps = (state, ownProps) => ({
  whitelistCodes: state.admin.whitelistCodes,
  users: state.admin.users
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addWhitelistCodes: (count) => dispatch(addWhitelistCodes('', {count: count})),
  handleEditUser: (user) => dispatch(editUserModal(user)),
  handleDeleteUser: (user) => handleDeleteUser(user, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin);
