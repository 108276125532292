import { connect } from 'react-redux'
import Dashboard from '../components/dashboard/Dashboard'
import {
  dismissModal, exportDataset,
  ModalTypes,
  navigate,
  Pages,
  requestDataset,
  requestDatasetOutput,
  setActiveDataset, setActiveDatasetToNewest,
  setModal
} from "../actions";

function mapStateToProps(state, ownProps) {
  const datasetId = state.activeDatasetId;
  let loading = true;
  let datasetOutput = null;
  let dataset = null;
  
  if (datasetId) {
    datasetOutput = state.entities.datasetOutputs[datasetId];
    dataset = state.entities.datasets[datasetId];
    if (datasetOutput && datasetOutput.fetching === false &&
      dataset && dataset.fetching === false) {
      loading = false;
    }
  }
  
  return {
    loading,
    dataset,
    datasetId,
    datasetList: state.entities.datasetList,
    datasetOutput
  }
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    exportDataset: (id) => dispatch(exportDataset(id)),
    selectDataset: (datasetId) => {
      dispatch(setActiveDataset(datasetId));
      dispatch(requestDataset(datasetId));
      dispatch(requestDatasetOutput(datasetId));
    },
    createDataset: () => {
      dispatch(setModal(ModalTypes.NEW_DATASET_FORM, {
        handleSuccess: (id) => {
          dispatch(dismissModal());
          dispatch(setActiveDataset(id));
          dispatch(navigate(Pages.QUESTIONNAIRE));
        }
      }));
    },
    deleteDataset: (id) => {
      dispatch(setModal(ModalTypes.DELETE_DATASET_CONFIRM, {
        datasetId: id,
        handleSuccess: () => {
          dispatch(dismissModal());
          dispatch(setActiveDatasetToNewest());
          dispatch(navigate(Pages.DASHBOARD));
        }
      }));
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)
