import "./EditSources.css"
import React from "react";

class EditSources extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      source: props.source,
      notationKey: props.notationKey,
      quality: props.quality,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    // High (H)	Detailed activity data
    // Medium (M)	Modeled data with robust assumptions
    // Low (L)	Highly modeled or uncertain  data

    // IE	Included elsewhere
    // NE	Not estimated
    // NO 	Not occurring
    // C	Confidential
    const quality = [
      {id: '', text: ''},
      {id: 'H', text: 'High: Detailed activity data'},
      {id: 'M', text: 'Medium: Modeled data with robust assumptions'},
      {id: 'L', text: 'Low: Highly modeled or uncertain data'},
    ];

    const notationKeys = [
      {id: '', text: ''},
      {id: 'IE', text: 'IE: Included elsewhere'},
      {id: 'NE', text: 'NE: Not estimated'},
      {id: 'NO', text: 'NO: Not occurring'},
      {id: 'C', text: 'C: Confidential'},
    ];

    return (
      <form className='editSourcesForm' onSubmit={this.handleSubmit}>
        <h3>Sources</h3>
        <label htmlFor='sourceField'>Sources:</label>
        <textarea name='source' id='sourceField' onChange={this.handleChange} value={this.state.source} />
        <div className='dropdownsContainer'>
          <div>
            <label htmlFor='notationKey'>Notation key:</label>
            <select id='notationKey' name='notationKey' onChange={this.handleChange} value={this.state.notationKey}>
              {notationKeys.map(key =>
                <option value={key.id} key={key.id}>{key.text}</option>
              )}
            </select>
          </div>
          <div>
            <label htmlFor='dataQuality'>Data quality:</label>
            <select id='dataQuality' name='quality' onChange={this.handleChange} value={this.state.quality}>
              {quality.map(opt =>
                <option value={opt.id} key={opt.id}>{opt.text}</option>
              )}
            </select>
          </div>
        </div>

        <input type='submit' value='Save' />
      </form>
    )
  }

  handleChange(event) {
    let changes = {};
    changes[event.target.name] = event.target.value;
    this.setState(changes);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      'source': this.state['source'],
      'notationKey': this.state['notationKey'],
      'quality': this.state['quality'],
    };
    this.props.handleSave(data);
  }
}

export default EditSources;
