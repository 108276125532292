import React from 'react';
import './WhitelistCodes.css'

const WhitelistCodes = ( {codes, handleAdd} ) => (
  <div className='whitelistCodes'>
    <h3>Whitelist Codes</h3>
    <div className='addCodesButton' onClick={() => handleAdd(1)}>Add Code</div>
    {codes.map( (code) =>
      <div className='whitelistCode' key={code.id}>
        <div className='code'>{code.code}</div>
        <div>Id: {code.id}</div>
        <div>Created by: {code.createdBy}</div>
        <div>{code.redeemedBy ? `Redeemed by user ID ${code.redeemedBy}` : `Unredeemed`}</div>
      </div>
    )}
  </div>
);

export default WhitelistCodes;
