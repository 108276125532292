import React from 'react'
import './AtAGlanceModule.css'
import AtAGlanceRow from "./AtAGlanceRow";

class AtAGlanceModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  render() {
    let values = {};
    this.props.data.rows.forEach(row => {
      values[row.label] = Number(row.value) || 0;
    });
    const onePlanet = values['OnePlanetLiving'];
    const cityTotal = values['CityMetabolism'];
    const cityCountryTotal = values['CityCountryMetabolism'];
    const cityMult = values['CityMetabolism'] / values['CityArea'];
    const cityCountryMult = values['CityCountryMetabolism'] / values['CityArea'];
    const cityPerCap = values['CityMetabolism'] / values['Population'];
    const cityCountryPerCap = values['CityCountryMetabolism'] / values['Population'];
    const cityEarthCount = cityPerCap / onePlanet;
    const cityCountryEarthCount = cityCountryPerCap / onePlanet;

    return (
      <div className={`atAGlanceModule roundedShadowBox expanded ${this.props.className || ''}`}>
        <h3>Overall Ecological Footprint at a glance</h3>
        <AtAGlanceRow title='City'
                      earthCount={cityEarthCount}
                      totalValue={cityTotal}
                      totalCaption='total ecological footprint'
                      totalMultiplier={cityMult}
                      perPersonValue={cityPerCap}
                      perPersonCaption='per person'
                      perPersonAvailable={onePlanet}
        />
        <div className='expandingContainer'>
          <AtAGlanceRow title='City and Country'
                        earthCount={cityCountryEarthCount}
                        totalValue={cityCountryTotal}
                        totalCaption='total ecological footprint'
                        totalMultiplier={cityCountryMult}
                        perPersonValue={cityCountryPerCap}
                        perPersonCaption='per person'
                        perPersonAvailable={onePlanet}
          />
        </div>
      </div>
    )
  }

  toggleExpanded() {
    this.setState({expanded: !this.state.expanded});
  }
}

export default  AtAGlanceModule;