import React from "react";
import './EditUser.css'
import {downloadDataset, editUserRequest, requestAdminUsers, setActiveDataset} from "../../actions";
import {connect} from "react-redux";

class EditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      failed: false,
      email: props.user.email,
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      isAdmin: props.user.admin,
      downloadStates: {}
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  render() {
    return (
      <div className='editUserForm'>
        <h3>Edit user (ID: {this.props.user.id})</h3>

        <form onSubmit={this.handleSubmit}>
          <label>Email:</label>
          <input type='text'
                 name='email'
                 onChange={this.handleChange}
                 value={this.state.email}
                 disabled={this.state.loading} />

          <label>First name:</label>
          <input type='text'
                 name='firstName'
                 onChange={this.handleChange}
                 value={this.state.firstName}
                 disabled={this.state.loading} />

          <label>Last name:</label>
          <input type='text'
                 name='lastName'
                 onChange={this.handleChange}
                 value={this.state.lastName}
                 disabled={this.state.loading} />

          <label className='inline'>Is admin:</label>
          <select name='isAdmin'
                  value={this.state.isAdmin ? 'true' : ''}
                  onChange={this.handleChange}
                  disabled={this.state.loading}>
            <option value=''>No</option>
            <option value='true'>Yes</option>
          </select>

          <input type='submit' value='Save' disabled={this.state.loading} />
        </form>
        <h4>Datasets</h4>
        {this.props.datasetList.loading &&
          <p>Loading...</p>
        }
        {this.props.datasetList.items &&
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>City</th>
                <th>Year</th>
                <th>Version</th>
                <th>Spec ID</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody>
              {this.props.datasetList.items.map( (dataset) =>
                <tr key={dataset.id}
                    onClick={() => this.props.setActiveDataset(dataset.id)}
                    className={this.props.activeDatasetId === dataset.id ? 'activeDataset' : ''}>
                  <td>{dataset.id}</td>
                  <td>{dataset.name}</td>
                  <td>{dataset.city}</td>
                  <td>{dataset.year}</td>
                  <td>{dataset.version}</td>
                  <td>{dataset.specId}</td>
                  <td className='downloadCell' onClick={(event) => this.handleDownload(dataset, event)}>
                    {this.state.downloadStates[dataset.id] || 'Download'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        }
      </div>
    )
  }

  handleDownload(dataset, event) {
    event.stopPropagation();
    this.setState({
      downloadStates: Object.assign({}, this.state.downloadStates, {[dataset.id]: 'Loading'})
    });
    this.props.downloadDataset(dataset.id).then( (json) => {
      this.setState({
        downloadStates: Object.assign({}, this.state.downloadStates, {[dataset.id]: false})
      });
      const a = document.createElement('a');
      a.download = `Dataset ${dataset.id}.xlsx`;
      a.href = json['url'];
      a.click();
    }).catch( (error) => {
      this.setState({
        downloadStates: Object.assign({}, this.state.downloadStates, {[dataset.id]: 'Failed'})
      });
      console.log(error);
    });
  }

  handleChange(event) {
    let changes = {};
    changes[event.target.name] = event.target.value;
    this.setState(changes);
  }

  handleSubmit(event) {
    event.preventDefault();
    const data = {
      'email': this.state.email,
      'first_name': this.state.firstName,
      'last_name': this.state.lastName,
      'admin': this.state.isAdmin
    };

    this.setState({
      loading: true,
      failed: false
    });

    this.props.editUser(this.props.user.id, data)
      .then((json) => {
        this.setState({
          loading: false,
          failed: false
        });
        this.props.requestUserList();
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loading: false,
          failed: true
        });
      });
  }
}

const mapStateToProps = (state, ownProps) => ({
  datasetList: state.admin.userDatasetLists[ownProps.user.id],
  activeDatasetId: state.activeDatasetId
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editUser: (id, data) => dispatch(editUserRequest(id, data)),
  requestUserList: () => dispatch(requestAdminUsers()),
  setActiveDataset: (id) => dispatch(setActiveDataset(id)),
  downloadDataset: (id) => dispatch(downloadDataset(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUser);
